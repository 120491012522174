import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ChangePassword from './ChangePassword';
import { Container, Grid } from "@material-ui/core";
import Profile from './Profile';
import SettingsIcon from "@material-ui/icons/Settings";
import history from "../../history";
import Button from "@material-ui/core/Button";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#e6e6e6",
    display: 'flex',
    borderRadius: "5px",
    width: "100%",
  },
  tabs: {
    minWidth: "200px",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  container: {
    marginTop: "2.75%",
    marginBottom: "2.75%",
  },
  settingsButton: {
    borderRadius: "50px",
    backgroundColor: "#214003",
    "&:hover": {
      backgroundColor: "#b6ff6f",
    },
    padding: "1rem",
    marginRight: "2rem",
    float: "right",
  },
  innercont: {
    backgroundColor: "#e6e6e6",
    padding: "1rem",
    borderRadius: "5px",
  },
}));

export default function Settings() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenOrdersPage = () => {
    history.push("/account");
  };

  return (
    <div>
      <Container maxWidth={"lg"} className={classes.container}>
        <div className={classes.innercont}>
          <Button
            color="primary"
            variant="contained"
            className={classes.settingsButton}
            onClick={handleOpenOrdersPage}
          >
            <SettingsIcon className={classes.extendedIcon} />
            My Orders
          </Button>
          <div className={classes.root}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              <Tab label="Profile" {...a11yProps(0)} />
              <Tab label="Change Password" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Profile />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ChangePassword />
            </TabPanel>
          </div>
        </div>
      </Container>
    </div>
  );
}
