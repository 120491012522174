import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import axios from "axios";
import CONSTANTS from "../../services/constants";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/ArrowDropUp";
import KeyboardArrowDownIcon from "@material-ui/icons/ArrowDropDown";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import TablePagination from "@material-ui/core/TablePagination";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  iconButt: {
    color: "black",
  },
});

const createData = (
  shippedName: any,
  date: any,
  paymentType: any,
  shippedAddress: any,
  totalAmount: any,
  deliveryStatus: any,
  product: any
) => {
  let newDate = date.slice(0, 10);
  if (deliveryStatus === 1) {
    deliveryStatus = "Shipped";
  } else {
    deliveryStatus = "Unshipped";
  }
  return {
    shippedName,
    orderDate: newDate,
    paymentType,
    shippedAddress,
    totalAmount,
    deliveryStatus,
    history: product,
  };
};

const Row = (props: any) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            color="inherit"
            className={classes.iconButt}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.shippedName}
        </TableCell>
        <TableCell align="left">{row.orderDate}</TableCell>
        <TableCell align="left">{row.paymentType}</TableCell>
        <TableCell align="left">{row.shippedAddress}</TableCell>
        <TableCell align="left">{row.deliveryStatus}</TableCell>
        <TableCell align="right">{row.totalAmount}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Order Items
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell align="right">Unit Price (Rs.)</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Total price (Rs.)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow: any, i: any) => (
                    <TableRow key={historyRow.productName}>
                      <TableCell component="th" scope="row">
                        {historyRow.productName}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.unitPrice}
                      </TableCell>
                      <TableCell align="right">{historyRow.quantity}</TableCell>
                      <TableCell align="right">
                        {historyRow.totalPrice}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    orderDate: PropTypes.string.isRequired,
    shippedAddress: PropTypes.string.isRequired,
    paymentType: PropTypes.string.isRequired,
    deliveryStatus: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number.isRequired,
        unitPrice: PropTypes.number.isRequired,
        productName: PropTypes.string.isRequired,
        totalPrice: PropTypes.number.isRequired,
      })
    ).isRequired,
    shippedName: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
  }).isRequired,
};

const OrderTable = (props: any) => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const status = props.status;
  const updateLoading = props.updateLoading;

  const handleChangePage = async (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    updateLoading(true);
    try {
      const res = await axios.post(
        `${CONSTANTS.HOSTNAME}/api/orders/get-all-orders/${localStorage.getItem(
          "userID"
        )}`,
        { status }
      );
      ordersIntoRows(res.data.data);
    } catch (err) {
      console.log(err);
    }
    updateLoading(false);
  };

  const ordersIntoRows = async (fetchedOrders: any) => {
    const rows: any[] = [];
    for (const order of fetchedOrders) {
      rows.push(
        // @ts-ignore
        createData(
          order.shippedName,
          order.orderDate,
          order.paymentType,
          order.shippedAddress,
          order.totalAmount,
          order.deliveryStatus,
          order.productDetails
        )
      );
    }
    // @ts-ignore
    setRows(rows);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow style={{backgroundColor: "#FAFAFA"}}>
              <TableCell />
              <TableCell style={{ fontWeight: "bold" }}>Shipped Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="left">
                Order Date
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="left">
                Payment Type
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="left">
                Shipped Address
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="left">
                Delivery Status
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">
                Total Amount&nbsp;(Rs.)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
                  {rows.length != 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      //@ts-ignore
                      .map((row) => {
                        // @ts-ignore
                        return <Row key={row.shippedName} row={row} />;
                      })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        <Typography variant="h5" component="body">
                          No Orders To Show !
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              style={{backgroundColor: "#FAFAFA"}}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
    </>
  );
};

export default OrderTable;
