import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Logo from "../../Assets/Images/Logo-only.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);



const PoliciesPage = () => {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth={"lg"}>
        <Grid>
          <Typography
            variant="h4"
            style={{
              marginTop: "40px",
              textAlign: "center",
              fontFamily: '"Times New Roman", Times, serif',
              fontWeight: "bold",
            }}
          >
            Privacy Policy 
            </Typography>
          <Typography
            style={{
              marginTop: "40px",
              textAlign: "justify",
              fontFamily: '"Times New Roman", Times, serif',
            }}>
            Your privacy is vital to us. It is our policy policy to respect your privacy regarding any information we may collect 
            from you across our website and other sites we own and operate.
            <br></br> <br></br> 
            <b>Information we collect</b>
            <br></br> 
            Log data -: 
            When you visit our website, our servers may automatically log the standard data provided by your web browser. This data 
            is considered non-identifying information, as it does not personally identify you on its own. It may include your 
            computers Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your 
            visit, the time spent on each page, and other details.
            <br></br>
            We may also collect data about the device you are using to access our website. This data may include the device type, 
            operating system, unique device identifiers, device settings, and geo-location data. What we collect can depend on the 
            individual settings of your device and software. We recommend checking the policies of your device manufacturer or 
            software provider to learn what information they make available to us.
            <br></br> <br></br>
            <b>Personal information</b>
            <br></br>
            We may ask for personal information, such as your: name, email, phone/mobile number, and payment information. This data 
            is considered identifying information, as it can personally identify you. We only request personal information relevant 
            to providing you with a service, and only use it to help provide or improve this service.
            <br></br><br></br>
            <b>How we collect information</b>
            <br></br>
            We collect information by fair and lawful means, with your knowledge and consent. We also let you know why we are 
            collecting it and how it will be used. You are free to refuse our request for this information, with the understanding 
            that we may be unable to provide you with some of your desired services without it.
            <br></br><br></br>
            <b>Use of information</b>
            <br></br>
            We may use a combination of identifying and non-identifying information to understand who our visitors are, how they use 
            our services, and how we may improve their experience of our website in future. We do not disclose the specifics of this 
            information publicly but may share aggregated and anonymized versions of this information, for example, in website and 
            customer usage trend reports.
            <br></br>
            We may use your personal details to contact you with updates about our website and services, along with promotional 
            content that we believe may be of interest to you. If you wish to opt out of receiving promotional content, you can 
            follow the unsubscribe instructions provided alongside any promotional correspondence from us.
            <br></br><br></br>    
            <b>Data processing and storage</b>
            <br></br>
            The personal information we collect is stored and processed in United States, or where we or our partners, affiliates 
            and third-party providers maintain facilities. We only transfer data within jurisdictions subject to data protection 
            laws that reflect our commitment to protecting the privacy of our users.
            <br></br>
            We only retain personal information for as long as necessary to provide a service, or to improve our services in future. 
            While we retain this data, we will protect it within commercially acceptable means to prevent loss and theft, as well as 
            unauthorized access, disclosure, copying, use or modification. That said, we advise that no method of electronic 
            transmission or storage is 100% secure and cannot guarantee absolute data security.
            <br></br>
            If you request your personal information, be deleted, or where your personal information becomes no longer relevant to 
            our operations, we will erase it from our system within a reasonable timeframe.
            <br></br><br></br>
            <b>Cookies</b>
            <br></br>
            We use cookies to collect information about you and your activity across our site. A cookie is a small piece of data 
            that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. 
            This helps us serve you content based on preferences you have specified. Please refer to our Cookie Policy for more 
            information.
            <br></br><br></br>
            <b>Third-party access to information</b>
            <br></br>
            We use third-party services for: analytics tracking, user authentication, advertising and promotion, email marketing, 
            and payment processing. These services may access our data solely for the purpose of performing specific tasks on our 
            behalf. We do not share any personally identifying information with them without your explicit consent. We do not give 
            them permission to disclose or use any of our data for any other purpose.
            <br></br>
            We may, from time to time, allow limited access to our data by external consultants and agencies for the purpose of 
            analysis and service improvement. This access is only permitted for as long as necessary to perform a specific function. 
            We only work with external agencies whose privacy policies align with ours.
            <br></br>
            We will refuse government and law enforcement requests for data if we believe a request is too broad or unrelated to its 
            stated purpose. However, we may cooperate if we believe the requested information is necessary and appropriate to comply 
            with legal process, to protect our own rights and property, to protect the safety of the public and any person, to 
            prevent a crime, or to prevent what we reasonably believe to be illegal, legally actionable, or unethical activity.
            <br></br>
            We do not otherwise share or supply personal information to third parties. We do not sell or rent your personal 
            information to marketers or third parties.
            <br></br><br></br>
            <b>Limits of our policy</b>
            <br></br>
            This privacy policy only covers ……………………………. own collecting and handling of data. We only work with partners, 
            affiliates, and third-party providers whose privacy policies align with ours, however we cannot accept responsibility 
            or liability for their respective privacy practices.
            <br></br>
            Our website may link to external sites that are not operated by us. Please be aware that we have no control over the 
            content and policies of those sites, and cannot accept responsibility or liability for their respective privacy 
            practices.
            <br></br><br></br>
            <b>Changes to this policy</b>
            <br></br>
            At our discretion, we may change our privacy policy to reflect current acceptable practices. We will take reasonable 
            steps to let users know about changes via our website. If you are a registered user on ………………………………., we will notify 
            you using the contact details saved in your account. Your continued use of this site after any changes to this policy 
            will be regarded as acceptance of our practices around privacy and personal information.
            <br></br><br></br>
            <b>Your rights and responsibilities</b>
            <br></br>
            As our user, you have the right to be informed about how your data is collected and used. You are entitled to know 
            what data we collect about you, and how it is processed. You are entitled to correct and update any personal 
            information about you, and to request this information be deleted. You may amend or remove your account information 
            at any time, using the tools provided in your account control panel.
            <br></br>
            You are entitled to restrict or object to our use of your data, while retaining the right to use your personal 
            information for your own purposes. You have the right to opt out of data about you being used in decisions based 
            solely on automated processing.
            <br></br><br></br>
            <b>This policy is effective as of 20 Oct 2021</b>
            <br></br><br></br>
            <b>Cookie Policy</b>
            <br></br>
            We use cookies to help improve your experience of https://www.americommerce.com. This cookie policy is part of the 
            AmeriCommerce privacy policy, and covers the use of cookies between your device and our site. We also provide basic 
            information on third-party services we may use, who may also use cookies as part of their service, though they are not 
            covered by our policy.
            <br></br>
            If you do not wish to accept cookies from us, you should instruct your browser to refuse cookies from 
            https://www.americommerce.com, with the understanding that we may be unable to provide you with some of your desired 
            content and services.
            <br></br><br></br>
            <b>What is a cookie?</b>
            <br></br>
            A cookie is a small piece of data that a website stores on your device when you visit, typically containing information 
            about the website itself, a unique identifier that allows the site to recognize your web browser when you return, 
            additional data that serves the purpose of the cookie, and the lifespan of the cookie itself.
            <br></br>
            Cookies are used to enable certain features (eg. logging in), to track site usage (eg. analytics), to store your user 
            settings (eg. time zone, notification preferences), and to personalize your content (eg. advertising, language).
            <br></br>
            Cookies set by the website you are visiting are normally referred to as first-party cookies, and typically only track 
            your activity on that particular site. Cookies set by other sites and companies (ie. third parties) are called 
            third-party cookies and can be used to track you on other websites that use the same third-party service.
            <br></br><br></br>
            <b>Types of cookies and how we use them</b>
            <br></br><br></br>
            <b>Essential cookies</b>
            <br></br>
            Essential cookies are crucial to your experience of a website, enabling core features like user logins, account 
            management, shopping carts and payment processing. We use essential cookies to enable certain functions on our website.
            <br></br><br></br>
            <b>Performance cookies</b>
            <br></br>
            Performance cookies are used in the tracking of how you use a website during your visit, without collecting personal 
            information about you. Typically, this information is anonymous and aggregated with information tracked across all site 
            users, to help companies understand visitor usage patterns, identify and diagnose problems or errors their users may 
            encounter, and make better strategic decisions in improving their overall website experience. These cookies may be set 
            by the website you are visiting (first-party) or by third-party services. We use performance cookies on our site.
            <br></br><br></br>
            <b>Functionality cookies</b>
            <br></br>
            Functionality cookies are used in collecting information about your device and any settings you may configure on the 
            website you are visiting (like language and time zone settings). With this information, websites can provide you with 
            customized, enhanced or optimized content and services. These cookies may be set by the website you are visiting 
            (first-party) or by third-party service. We use functionality cookies for selected features on our site.
            <br></br><br></br>
            <b>Targeting/advertising cookies</b>
            <br></br>
            Targeting/advertising cookies are used in determining what promotional content is more relevant and appropriate to you 
            and your interests. Websites may use them to deliver targeted advertising or to limit the number of times you see an 
            advertisement. This helps companies improve the effectiveness of their campaigns and the quality of content presented 
            to you. These cookies may be set by the website you are visiting (first-party) or by third-party services. 
            Targeting/advertising cookies set by third-parties may be used to track you on other websites that use the same 
            third-party service. We use targeting/advertising cookies on our site.
            <br></br><br></br>
            <b>Third-party cookies on our site</b>
            <br></br>
            We may employ third-party companies and individuals on our websites-for example, analytics providers and content 
            partners. We grant these third parties access to selected information to perform specific tasks on our behalf. They may 
            also set third-party cookies in order to deliver the services they are providing. Third-party cookies can be used to 
            track you on other websites that use the same third-party service. As we have no control over third-party cookies, they 
            are not covered by the ……………………….cookie policy.
            <br></br><br></br>
            <b>Our third-party privacy promises</b>
            <br></br>
            We review the privacy policies of all our third-party providers before enlisting their services to ensure their 
            practices align with ours. We will never knowingly include third-party services that compromise or violate the privacy 
            of our users.
            <br></br><br></br>
            <b>How you can control or opt out of cookies</b>
            <br></br>
            If you do not wish to accept cookies from us, you can instruct your browser to refuse cookies from our website. Most 
            browsers are configured to accept cookies by default, but you can update these settings to either refuse cookies 
            altogether, or to notify you when a website is trying to set or update a cookie.
            <br></br>
            If you browse websites from multiple devices, you may need to update your settings on each individual device.
            <br></br>
            Although some cookies can be blocked with little impact on your experience of a website, blocking all cookies may mean 
            you are unable to access certain features and content across the sites you visit.
            </Typography>
            <Typography
            variant="h4"
            style={{
              marginTop: "40px",
              textAlign: "center",
              fontFamily: '"Times New Roman", Times, serif',
              fontWeight: "bold",
            }}
          >
            Kudamalana Cart Return Policy
            </Typography>
            <Typography
            style={{
              marginTop: "40px",
              textAlign: "justify",
              fontFamily: '"Times New Roman", Times, serif',
            }}>
            <b>Returns</b>
            <br></br>
            We accept returns. You can return unopened items in the original packaging within 30 days of your purchase with receipt 
            or proof of purchase. If 30 days or more have passed since your purchase, we cannot offer you a refund or an exchange.
            <br></br><br></br>
            <b>You can send the item you consider defective to:</b>
            <br></br>
            <i>Kudamalana Estate,</i>
            <br></br>
            <i>Thalgaswala,</i> 
            <br></br>
            <i>Sri Lanka.</i>
            <br></br><br></br>
            Upon receipt of the returned item, we will fully examine it and notify you via email, within a reasonable period of 
            time, whether you are entitled to a return. If you are entitled to a return, we will refund your purchase price and a 
            credit will automatically be applied to your original method of payment.
            <br></br><br></br>
            Only regular priced items may be refunded. Sale items are non-refundable.
            <br></br><br></br>
            To follow-up on the status of your return, please contact us at <i>kudamalana@gmail.com</i>
            <br></br><br></br>
            Refunds do not include any shipping and handling charges shown on the packaging slip or invoice. Shipping charges for 
            all returns must be prepaid and insured by you. You are responsible for any loss or damage to hardware during shipment. 
            We do not guarantee that we will receive your returned item. Shipping and handling charges are not refundable. Any 
            amounts refunded will not include the cost of shipping.
            </Typography>
        </Grid>
      </Container>
    </>
  );
};

//@ts-ignore
export default PoliciesPage;
