import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  Container,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import axios from "axios";
import CONSTANTS from "../../../services/constants";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/ArrowDropUp";
import KeyboardArrowDownIcon from "@material-ui/icons/ArrowDropDown";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    maxHeight: 440,
    tableLayout: "fixed",
  },
  paper: {
    marginTop: "2rem",
    width: "80%",
    marginLeft: "10%",
    backgroundColor: "#FAFAFA",
  },
  searchBox: {
    marginLeft: "40%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    justifyContent: "center",
  },
}));


const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const createData = (
  orderID: any,
  shippedName: any,
  date: any,
  paymentType: any,
  shippedAddress: any,
  totalAmount: any,
  deliveryStatus: any,
  product: any
) => {
  let newDate = date.slice(0, 10);
  if (deliveryStatus === 1) {
    deliveryStatus = true;
  } else {
    deliveryStatus = false;
  }
  return {
    orderID,
    shippedName,
    orderDate: newDate,
    paymentType,
    shippedAddress,
    totalAmount,
    deliveryStatus,
    history: product,
  };
};

const Row = (props: any) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [delStat, setDelStat] = React.useState(row.deliveryStatus);
  const [error, setError] = useState("");
  const changeDeliveryStatus = async (event: any) => {
    setDelStat(!delStat);
    Toast.fire({
      icon: "success",
      title: "Delivery Status Updated Successfully",
    });
  };

  async function updateDeliveryStatus() {
    try {
      const res = await axios.put(
        `${CONSTANTS.HOSTNAME}/api/orders/update-delivery-status/${row.orderID}`,
        { delStat }
      );
    } catch (e) {
      setError(e.message);
    }
  }

  useEffect(() => {
    updateDeliveryStatus();
  }, [delStat]);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            color="inherit"
            style={{color: "black"}}
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon/>
            ) : (
              <KeyboardArrowDownIcon/>
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.orderID}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.shippedName}
        </TableCell>
        <TableCell align="left">{row.orderDate}</TableCell>
        <TableCell align="left">{row.paymentType}</TableCell>
        <TableCell align="left">{row.shippedAddress}</TableCell>
        <TableCell align="center">
          <Checkbox
            disabled={props.role}
            checked={delStat}
            onChange={changeDeliveryStatus}
          ></Checkbox>
        </TableCell>
        <TableCell align="right">{row.totalAmount}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Order Items
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell align="right">Unit Price (Rs.)</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Total price (Rs.)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow: any, i: any) => (
                    <TableRow key={historyRow.productName}>
                      <TableCell component="th" scope="row">
                        {historyRow.productName}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.unitPrice}
                      </TableCell>
                      <TableCell align="right">{historyRow.quantity}</TableCell>
                      <TableCell align="right">
                        {historyRow.totalPrice}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    orderDate: PropTypes.string.isRequired,
    shippedAddress: PropTypes.string.isRequired,
    paymentType: PropTypes.string.isRequired,
    deliveryStatus: PropTypes.string.isRequired,
    orderID: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number.isRequired,
        unitPrice: PropTypes.number.isRequired,
        productName: PropTypes.string.isRequired,
        totalPrice: PropTypes.number.isRequired,
      })
    ).isRequired,
    shippedName: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
  }).isRequired,
};

const Sales = (props: any) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [error, setError] = useState("");
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deliveryStat, setDeliveryStat] = React.useState("All");
  const deliveryStatusChange = (event: any) => {
    setDeliveryStat(event.target.value);
  };

  async function renderDeliveryStatus() {
    setLoading(true);
    try {
      const res = await axios.post(
        `${CONSTANTS.HOSTNAME}/api/orders/get-all-orders-on-delivery-status`,
        { deliveryStat }
      );
      ordersIntoRows(res.data.data);
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  }

  useEffect(() => {
    renderDeliveryStatus();
  }, [deliveryStat]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleChangePage = async (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${CONSTANTS.HOSTNAME}/api/orders/get-all-orders`
      );
      ordersIntoRows(res.data.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const ordersIntoRows = async (fetchedOrders: any) => {
    const rows: any[] = [];
    for (const order of fetchedOrders) {
      rows.push(
        // @ts-ignore
        createData(
          order.orderID,
          order.shippedName,
          order.orderDate,
          order.paymentType,
          order.shippedAddress,
          order.totalAmount,
          order.deliveryStatus,
          order.productDetails
        )
      );
    }
    // @ts-ignore
    setRows(rows);
  };

  return (
    <>
      <div className={classes.root}>
        <Backdrop open={loading} className={classes.backdrop}>
          <CircularProgress />
        </Backdrop>
        <Container maxWidth={"lg"}>
          <Paper className={classes.paper}>
            <Grid item>
              <div className={classes.searchBox}>
              <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel htmlFor="delivery-status-select">
                    Delivery Status
                  </InputLabel>
                  <Select
                  
                    value={deliveryStat}
                    onChange={deliveryStatusChange}
                    label="delivery status"
                    inputProps={{
                      id: "delivery-status-select",
                    }}
                  >
                    <MenuItem value={"Shipped"}>Shipped</MenuItem>
                    <MenuItem value={"Unshipped"}>Unshipped</MenuItem>
                    <MenuItem value={"All"}>All</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Paper>
        </Container>
        <Container maxWidth={"lg"}>
          <Paper className={classes.paper}>
            <TableContainer component={Paper} className={classes.container}>
              <Table aria-label="collapsible table">
                <TableHead style={{backgroundColor: "#FAFAFA"}}>
                  <TableRow>
                    <TableCell />
                    <TableCell style={{ fontWeight: "bold" }}>
                      Order ID
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Shipped Name
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Order Date
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Payment Type
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Shipped Address
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Delivery Status
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">
                      Total Amount&nbsp;(Rs.)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length != 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      //@ts-ignore
                      .map((row) => {
                        // @ts-ignore
                        return <Row key={row.shippedName} row={row} role={props.role}/>;
                      })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        <Typography variant="h5" component="body">
                          No Orders To Show !
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Container>
      </div>
    </>
  );
};

export default Sales;
