import React, {useEffect, useState} from 'react';
import axios from "axios";
import CONSTANTS from "../../../services/constants";
//import './payment_modal.css'

const PaymentModal = (props) => {
    const [shippingDetails, setShippingDetails] = useState(props.shippingDetails);
    const [orderDetails, setOrderDetails] = useState(props.orderDetails);

    // Put the payment variables here
    var payment = {
        sandbox: true, // if the account is sandbox or real
        merchant_id: '1217628', // Replace your Merchant ID
        return_url: 'http://localhost:3000/return',
        cancel_url: 'http://localhost:3000/cancel',
        notify_url: 'http://localhost:3000/notify',
        order_id: props.orderId,
        items: props.name,
        amount: props.amount,
        currency: 'LKR',
        first_name: shippingDetails.firstName,
        last_name: shippingDetails.lastName,
        email: '',
        phone: shippingDetails.telephone,
        address: shippingDetails.addressLine1 + shippingDetails.addressLine2,
        city: shippingDetails.city,
        country: shippingDetails.country,
    };

    useEffect(() => {
        console.log("pay");
        if (props.func)
            pay();
    }, [props.func]);

    // Called when user completed the payment. It can be a successful payment or failure
    window.payhere.onCompleted = async function onCompleted(orderId) {


        let today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;
        const userId = localStorage.getItem("userID");
        const orderDetails = {
            firstName: shippingDetails.firstName,
            lastName: shippingDetails.lastName,
            addressLine1:shippingDetails.addressLine1,
            addressLine2:shippingDetails.addressLine2,
            city:shippingDetails.city,
            province:shippingDetails.province,
            country:shippingDetails.country,
            postalCode:shippingDetails.postalCode,
            orderDate: today,
            paymentType: "CARD",
            amount: props.amount,
            deliveryStatus: 0,
            userId:userId,
            items:props.orderDetails
        }
        try {
            console.log("...awaiting complete")
            const shipping = await axios.post(`${CONSTANTS.HOSTNAME}/api/orders/create-order`, (orderDetails));
            console.log(shipping.data);
            console.log("Payment completed. OrderID:" + shipping.data.order.orderId);
            console.log(shippingDetails);
            console.log(props.orderDetails);
            props.donePayment();
        } catch (e) {
            console.log(e.data);
        }
        //Note: validate the payment and show success or failure page to the customer
    };

    // Called when user closes the payment without completing
    window.payhere.onDismissed = function onDismissed() {
        //Note: Prompt user to pay again or show an error page
        console.log("Payment dismissed");
        props.donePayment();
    };

    // Called when error happens when initializing payment such as invalid parameters
    window.payhere.onError = function onError(error) {
        // Note: show an error page
        console.log("Error:" + error);
        props.donePayment();

    };

    function pay() {
        console.log("pay");
        window.payhere.startPayment(payment);
    }

    return (
        <>
        </>
    )
};

export default PaymentModal;