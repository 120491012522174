import React, { useState } from "react";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import OrderTable from "./orderTable";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function UnShipped() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  // @ts-ignore
  const updateLoading = (status: boolean) => setLoading(status);

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <OrderTable status={0} updateLoading={updateLoading} />
    </>
  );
}
