import React, { useRef, useState } from "react";
import {Link, useLocation} from "react-router-dom";
import { 
    Button, 
    Card, 
    CardContent, 
    Container, 
    createStyles, 
    Grid, 
    makeStyles, 
    TextField, 
    Theme, 
    Typography, 
    Backdrop, 
    CircularProgress 
} from "@material-ui/core";

import contactImage from "../../Assets/Images/Contact.png";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from '@material-ui/icons/Phone';
import HomeIcon from '@material-ui/icons/Home';
import axios from 'axios';
import CONSTANTS from "../../services/constants";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        contactText: {
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            fontWeight: 500,
            // textShadow: "6px 6px 5px #a9a9a9",
            marginTop: "2rem",
            marginBottom: "1rem",
            // textAlign: "center",
        },
        contactImage: {
            // marginTop: "1rem",
            height: "100%",
            width: "100%",
            borderRadius: "0.5rem",
            border: "none",
        },
    })
);


const Contactuspage = () => {

    
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const firstNameRef = useRef<HTMLInputElement>();
    const lastNameRef = useRef<HTMLInputElement>();
    const emailRef = useRef<HTMLInputElement>();
    const messageRef = useRef<HTMLInputElement>();

    const getFormDetails = () => {
        let details = {
            firstName : "",
            lastName : "",
            email : "",
            message : ""}

        if (firstNameRef.current) details.firstName = firstNameRef.current.value;
        if (lastNameRef.current) details.lastName = lastNameRef.current.value;
        if (emailRef.current) details.email = emailRef.current.value;
        if (messageRef.current) details.message = messageRef.current.value;
        
        return details;
    };

    const clearForm = () => {
        if (firstNameRef.current) firstNameRef.current.value = '';
        if (lastNameRef.current) lastNameRef.current.value = '';
        if (emailRef.current) emailRef.current.value = '';
        if (messageRef.current) messageRef.current.value = '';
    }

    const sendMail = async (event: any) => {
        event.preventDefault();
        const formDetails = getFormDetails();

        setLoading(true);
        try {
            const res = await axios.post(`${CONSTANTS.HOSTNAME}/api/emails/send-contact-mail`, formDetails);
            if(res.status === 200) {
                setLoading(false);
                await Swal.fire({
                    title: "Message Sent Successfully!",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000
                });
                clearForm();
            } else {
                Swal.fire({
                    title: "Message Not Sent, Try Again!",
                    icon: "error",
                    confirmButtonText: 'OK'
                });
            }
        } catch(err) {
            Swal.fire({
                title: "Message Not Sent, Try Again!",
                icon: "error",
                confirmButtonText: 'OK'
            });
            console.log(err);
        }
        setLoading(false);
    }

    return(
        <>
            <Backdrop open={loading} className={classes.backdrop}>
                <CircularProgress />
            </Backdrop>
            <Container maxWidth={"lg"}>
                
                <Typography variant="h5" className={classes.contactText} >
                    Getting in Touch is Easy!
                </Typography>
                <img src={contactImage} width="100%" className={classes.contactImage}/>
                

                <Grid container>
                    <Grid container style={{border: "2px solid #e6e6e6", backgroundColor:"#e6e6e6", borderRadius:"5px", padding:"10px", marginTop: "1rem"}} xs={6}>
                        <Grid style={{marginRight: "1rem"}}>
                            <EmailIcon/>
                        </Grid>
                        <Grid>
                            <Typography style={{wordWrap: "break-word"}}>kudamalana@gmail.com</Typography>
                        </Grid>
                    </Grid>
                    <Grid xs={1} />
                    <Grid container style={{border: "2px solid #e6e6e6", backgroundColor:"#e6e6e6", borderRadius:"5px", padding:"10px", marginTop: "1rem"}} xs={5}>
                        <Grid style={{marginRight: "1rem"}}>
                            <PhoneIcon/>
                        </Grid>
                        <Grid>
                            <Typography style={{wordWrap: "break-word"}}>+94 91 420 3449</Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={{border: "2px solid #e6e6e6", backgroundColor:"#e6e6e6", borderRadius:"5px", padding:"10px", marginTop: "1rem", marginBottom: "1rem"}} xs={12}>
                        <Grid style={{marginRight: "1rem"}}>
                            <HomeIcon/>
                        </Grid>
                        <Grid>
                            <Typography style={{wordWrap: "break-word"}}>Kudamalana Estate, Thalgaswala Nagoda, 80470</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <hr/>

                <Card style={{maxWidth: "60%", margin: "0 auto", marginTop: "5rem", padding: "20px 5px"}}>
                    <CardContent>
                        <Typography style={{marginBottom: "1rem"}}>Got a question? We'd love to hear from you. Send us a message and We'll respond as soon as possible.</Typography>
                        <form onSubmit={sendMail}>
                            <Grid container spacing={1}>
                                <Grid xs={12} sm={6} item>
                                    <TextField label="First Name" inputRef={firstNameRef} placeholder="Enter First Name" variant="outlined" fullWidth required/>
                                </Grid>
                                <Grid xs={12} sm={6} item>
                                    <TextField label="Last Name" inputRef={lastNameRef} placeholder="Enter Last Name" variant="outlined" fullWidth required/>
                                </Grid>
                                <Grid xs={12} item>
                                    <TextField type="email" label="Email" inputRef={emailRef} placeholder="Enter Email" variant="outlined" fullWidth required/>
                                </Grid>
                                <Grid xs={12} item>
                                    <TextField label="Message" multiline rows={5} inputRef={messageRef} placeholder="Type your message here" variant="outlined" fullWidth required/>
                                </Grid>
                                <Grid xs={12} item>
                                    <Button type="submit" variant="contained" color="primary" fullWidth>Submit</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
                
            </Container>
        </>
    );
}


//@ts-ignore
export default Contactuspage;
