import React, { Component, useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import MainMenu from "../components/MainMenu/mainmenu";
import Swal from "sweetalert2";
import history from "../history";
import StickyFooter from "../components/Footer/footer";
import { Auth } from "aws-amplify";
import UserContext from "../helpers/UserContext";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PublicRoute = ({ component: Component, ...rest }) => {
  const classes = useStyles();

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setContext } = useContext(UserContext);

  const authenticate = async () => {
    setLoading(true);
    try {
      const user = (await Auth.currentAuthenticatedUser()).attributes;
      setContext(user);
      setAuthenticated(true);
      setLoading(false);
      return true;
    } catch (error) {
      setAuthenticated(false);
      console.log(error);
      setLoading(false);
      return false;
    }
  };

  useEffect(() => {
    authenticate().then(() => console.log("Authenticated!"));
  }, []);

  //Use this to enforce security within a public route
  //Should be used to validate auth when certain functionalities are triggered
  const validateAuth = async () => {
    if (!(await authenticate())) {
      await Swal.fire({
        allowOutsideClick: false,
        title: "You need to be logged in",
        showCancelButton: false,
        confirmButtonColor: "#214003",
        confirmButtonText: "Login",
      });
      history.push({
        pathname: "/login",
        state: {
          toUrl: rest.location.pathname,
        },
      });
      return false;
    }
    return true;
  };

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <MainMenu isAuthenticated={authenticated} />
      <Route
        {...rest}
        render={(routeProps) => (
          <Component {...routeProps} {...rest} validateAuth={validateAuth} />
        )}
      />{" "}
      <StickyFooter />
    </>
  );
};

export default PublicRoute;
