import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import AdminMenu from "../components/Adminpanel/AdminMenu/AdminMenu.tsx";
import SideBar from "../components/Adminpanel/SideBar/SideBar";
import Grid from "@material-ui/core/Grid";
import history from "../history";
import { Auth } from "aws-amplify";

const AdminRoute = (props) => {
  useEffect(() => {
    verifyAdmin().then((res) => {
      if (res) history.push("/");
    });
  }, []);

  const verifyAdmin = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      const ROLE = localStorage.getItem("ROLE");
      if (ROLE === "SUPER" || ROLE === "ADMIN") return false;
      return true;
    } catch (e) {
      return true;
    }
  };

  const { component: Component, ...rest } = props;
  return (
    <>
      <AdminMenu />
      <Grid container direction="row">
        <Grid item xs={2}>
          <SideBar />
        </Grid>
        <Grid item xs={10}>
          <Route
            {...rest}
            render={(props) => (
              <Component
                {...props}
                role={localStorage.getItem("ROLE") !== "SUPER"}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default AdminRoute;
