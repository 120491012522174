import React, { useRef, useState } from "react";
import { Auth } from "aws-amplify";
import history from "../../history";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();

  const emailRef = useRef<HTMLInputElement>();
  const verificationCodeRef = useRef<HTMLInputElement>();
  const passwordRef = useRef<HTMLInputElement>();
  const confirmPasswordRef = useRef<HTMLInputElement>();

  const [resetting, setResetting] = useState(false);
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [passwordError, setPasswordError] = useState({
    error: false,
    message:
      "Passwords should contain at least 8 characters of numbers and letters",
  });
  const [loading, setLoading] = useState(false);

  const getCode = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (emailRef.current) {
      if (/^(.+)@(.+){2,}\.(.+){2,}$/.test(emailRef.current.value)) {
        try {
          await Auth.forgotPassword(emailRef.current.value);
          setResetting(true);
        } catch (e) {
          setError(e.message);
          console.log(e);
        }
      } else setEmailError("Email is not valid.");
    }
    setLoading(false);
  };

  const resetPassword = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (
      passwordRef.current &&
      confirmPasswordRef.current &&
      verificationCodeRef.current
    ) {
      const code = verificationCodeRef.current.value,
        password = passwordRef.current.value,
        confirmPassword = confirmPasswordRef.current.value;
      let flag = true;
      if (!/^\d{6}$/.test(code)) {
        setCodeError("Something appears to be wrong with the code");
        flag = false;
      }
      if (password.length < 8) {
        setPasswordError({
          error: true,
          message:
            "Passwords should contain at least 8 characters of numbers and letters",
        });
        flag = false;
      } else if (password !== confirmPassword) {
        setPasswordError({
          error: true,
          message: "Passwords do not match",
        });
        flag = false;
      }
      if (flag && emailRef.current) {
        try {
          await Auth.forgotPasswordSubmit(
            emailRef.current.value,
            code,
            password
          );
          console.log("password changed successfully! log back in");
          history.push({
            pathname: "/login",
            state: {
              from: "passwordReset",
            },
          });
        } catch (e) {
          console.log(e);
          setError(e.message);
          setLoading(false);
        }
      }
    }
  };

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <RotateLeftIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot password
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={resetting ? resetPassword : getCode}
          >
            <TextField
              error={!!emailError}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email Address"
              autoComplete="email"
              autoFocus
              inputRef={emailRef}
              InputProps={{
                readOnly: resetting,
              }}
              onChange={() => setEmailError("")}
              helperText={emailError}
            />
            <Collapse in={resetting}>
              <TextField
                error={!!codeError}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Verification code"
                autoFocus
                inputRef={verificationCodeRef}
                helperText={codeError}
                onChange={() => {
                  if (codeError !== "") setCodeError("");
                  if (error !== "") setError("");
                }}
              />
              <TextField
                error={passwordError.error}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                inputRef={passwordRef}
                helperText={passwordError.message}
                onChange={() => {
                  setPasswordError({
                    error: false,
                    message:
                      "Passwords should contain at least 8 characters of numbers and letters",
                  });
                  if (error !== "") setError("");
                }}
              />
              <TextField
                error={passwordError.error}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Confirm password"
                type="password"
                inputRef={confirmPasswordRef}
                onChange={() => {
                  setPasswordError({
                    error: false,
                    message:
                      "Passwords should contain at least 8 characters of numbers and letters",
                  });
                  if (error !== "") setError("");
                }}
              />
            </Collapse>
            <Collapse in={!!error}>
              <Alert severity="error">{error}</Alert>
            </Collapse>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {resetting ? "Change password" : "Get code"}
            </Button>
          </form>
        </div>
      </Container>
    </>
  );
};

export default ForgotPassword;
