import React, { Component, useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import MainMenu from "../components/MainMenu/mainmenu";
import history from "../history";
import StickyFooter from "../components/Footer/footer";
import { Auth } from "aws-amplify";
import UserContext from "../helpers/UserContext";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const AuthRoute = (props) => {
  const classes = useStyles();

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setContext } = useContext(UserContext);

  const authenticate = async () => {
    setLoading(true);
    try {
      const user = (await Auth.currentAuthenticatedUser()).attributes;
      setContext(user);
      setAuthenticated(true);
      setLoading(false);
      history.push("/");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    authenticate().then(() => console.log("Authenticated!"));
  }, []);

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <MainMenu isAuthenticated={authenticated} />
      <Route {...props} render={(props) => <Component {...props} />} />
      <StickyFooter />
    </>
  );
};

export default AuthRoute;
