import React, { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import {
  Container,
  Backdrop,
  CircularProgress,
  TextField,
  Grid,
  Button,
  Typography,
  Collapse,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius:"5px",
    padding:"2rem",
    marginLeft:"1rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",

  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "50%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),

  },
  container: {
    marginBottom: "2.75%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ChangePassword() {
  const classes = useStyles();

  const passwordRef = useRef<HTMLInputElement>();
  const oldPasswordRef = useRef<HTMLInputElement>();
  const confirmPasswordRef = useRef<HTMLInputElement>();

  const [passwordError, setPasswordError] = useState({
    error: false,
    message:
      "Passwords should contain at least 8 characters of numbers and letters",
  });
  const [oldPasswordError, setOldPasswordError] = useState({
    error: false,
    message:
      "Passwords should contain at least 8 characters of numbers and letters",
  });
  const [confirmPasswordError, setConfirmPasswordError] = useState({
    error: false,
    message: "",
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    initState();
  }, [])

  const initState = async () => {
    const authenticatedUser = await Auth.currentAuthenticatedUser();
    setUser(authenticatedUser);
  }

  const changePassword = async (event: any) => {
    event.preventDefault();
    const formDetails = getFormDetails();
    const { password, oldPassword } = formDetails;
    setLoading(true);
    if (validateFormDetails(formDetails)) {
      try{
        const usr = await Auth.changePassword(user, oldPassword, password);
        //console.log("After Change Password");
        //console.log(usr);
        // @ts-ignore
        passwordRef.current.value = "";
        // @ts-ignore
        oldPasswordRef.current.value = "";
        // @ts-ignore
        confirmPasswordRef.current.value = "";
        await Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Password changed successfully',
          showConfirmButton: false,
          timer: 1500
        })
      }catch(e){
        await Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Something went wrong',
          showConfirmButton: false,
          timer: 1500
        })
      }
    }
    setLoading(false);
  };

  const getFormDetails = () => {
    let password = "",
      oldPassword = "",
      confirmPassword = "";

    if (passwordRef.current) password = passwordRef.current.value;
    if (oldPasswordRef.current) oldPassword = oldPasswordRef.current.value;
    if (confirmPasswordRef.current)
      confirmPassword = confirmPasswordRef.current.value;
    return { password, oldPassword, confirmPassword };
  };

  const validateFormDetails = (formDetails: any) => {
    let flag = true;
    const { password, oldPassword, confirmPassword } = formDetails;
    if (
      (password === "" && confirmPassword === "") ||
      password.length < 8)
      {
      flag = false;
      setPasswordError({
        error: true,
        message:
          "Passwords should contain at least 8 characters of numbers and letters",
      });
    } else if (password !== confirmPassword) {
      flag = false;
      setConfirmPasswordError({
        error: true,
        message: "Passwords do not match",
      });
    } else if (oldPassword < 8) {
      flag = false;
      setOldPasswordError({
        error: true,
        message: "Old Password should contain at least 8 characters of numbers and letters",
      });
    }
    return flag;
  };

  return (
      <div className={classes.root}>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
        <Container maxWidth={"lg"} className={classes.container}>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <form className={classes.form} noValidate onSubmit={changePassword}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  error={oldPasswordError.error}
                  variant="outlined"
                  required
                  fullWidth
                  label="Old Password"
                  type="password"
                  inputRef={oldPasswordRef}
                  helperText={oldPasswordError.message}
                  onChange={() =>
                    setOldPasswordError({
                      error: false,
                      message:
                        "Password should contain at least 8 characters of numbers and letters",
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={passwordError.error}
                  variant="outlined"
                  required
                  fullWidth
                  label="Password"
                  type="password"
                  inputRef={passwordRef}
                  helperText={passwordError.message}
                  onChange={() =>
                    setPasswordError({
                      error: false,
                      message:
                        "Passwords should contain at least 8 characters of numbers and letters",
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={confirmPasswordError.error}
                  variant="outlined"
                  required
                  fullWidth
                  label="Confirm password"
                  type="password"
                  inputRef={confirmPasswordRef}
                  helperText={confirmPasswordError.message}
                  onChange={() =>
                    setConfirmPasswordError({
                      error: false,
                      message:
                        "Passwords should contain at least 8 characters of numbers and letters",
                    })
                  }
                />
              </Grid>
            </Grid>
            <Collapse in={!!error}>
              <Alert severity="error">{error}</Alert>
            </Collapse>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Change Password
            </Button>
            <Grid container justify="flex-end"></Grid>
          </form>
        </div>
        </Container>
    </div>
  );
}
