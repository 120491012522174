import React, {useRef, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {makeStyles} from "@material-ui/core/styles";
import {Auth} from "aws-amplify";
import axios from "axios";
import CONSTANTS from "../../services/constants";
import history from "../../history";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

const AddressForm = (props: any) => {
    const classes = useStyles();

    const firstNameRef = useRef<HTMLInputElement>();
    const lastNameRef = useRef<HTMLInputElement>();
    const addressLine1Ref = useRef<HTMLInputElement>();
    const telephoneRef = useRef<HTMLInputElement>();
    const addressLine2Ref = useRef<HTMLInputElement>();
    const cityRef = useRef<HTMLInputElement>();
    const provinceRef = useRef<HTMLInputElement>();
    const postalCodeRef = useRef<HTMLInputElement>();
    const countryRef = useRef<HTMLInputElement>();
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        telephone: "",
        city: "",
        province: "",
        postalCode: "",
        country: ""
    })
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [addressLine1Error, setAddressLine1Error] = useState("");
    const [addressLine2Error, setAddressLine2Error] = useState("");
    const [telephoneError, setTelephoneError] = useState("");
    const [cityError, setCityError] = useState("");
    const [provinceError, setProvinceError] = useState("");
    const [postalCodeError, setPostalCodeError] = useState("");
    const [countryError, setCountryError] = useState("");

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const saveAddress = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        let firstName = "",
            lastName = "",
            addressLine1 = "",
            addressLine2 = "",
            telephone = "",
            city = "",
            province = "",
            postalCode = "",
            country = "";

        if (firstNameRef.current) firstName = firstNameRef.current.value;
        if (lastNameRef.current) lastName = lastNameRef.current.value;
        if (addressLine1Ref.current) addressLine1 = addressLine1Ref.current.value;
        if (addressLine2Ref.current) addressLine2 = addressLine2Ref.current.value;
        if (telephoneRef.current) telephone = telephoneRef.current.value;
        if (cityRef.current) city = cityRef.current.value;
        if (provinceRef.current) province = provinceRef.current.value;
        if (postalCodeRef.current) postalCode = postalCodeRef.current.value;
        if (countryRef.current) country = countryRef.current.value;

        if (firstName === "" && lastName === "" && addressLine1 === "" && addressLine2 === "" && telephone === "" && city === "" && province === "" && postalCode === "" && country === "") {
            setFirstNameError("Cannot be empty");
            setLastNameError("Cannot be empty");
            setAddressLine1Error("Cannot be empty");
            setAddressLine2Error("Cannot be empty");
            setTelephoneError("Cannot be empty");
            setCityError("Cannot be empty");
            setProvinceError("Cannot be empty");
            setPostalCodeError("Cannot be empty");
            setCountryError("Cannot be empty");

        } else if (firstName === "") setFirstNameError("Cannot be empty");
        else if (lastName === "") setLastNameError("Cannot be empty");
        else if (addressLine1 === "") setAddressLine1Error("Cannot be empty");
        else if (addressLine2 === "") setAddressLine2Error("Cannot be empty");
        else if (telephone === "") setTelephoneError("Cannot be empty");
        else if (!/^\+94[\d]{9}$/.test(telephone)) {
            setTelephoneError("Provide the complete number, including the country code");
        } else if (city === "") setCityError("Cannot be empty");
        else if (province === "") setProvinceError("Cannot be empty");
        else if (postalCode === "") setPostalCodeError("Cannot be empty");
        else if (country === "") setCountryError("Cannot be empty");
        else {
            setState(prevState => ({
                ...prevState,
                firstName: firstName,
                lastName: lastName,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                telephone: telephone,
                city: city,
                province: province,
                postalCode: postalCode,
                country: country
            }))

            const shippingDetails = {
                firstName,
                lastName,
                addressLine1,
                addressLine2,
                telephone,
                city,
                province,
                postalCode,
                country
            }

            try {
                props.handleNext(shippingDetails);
                // const res = await axios.post(
                //     `${CONSTANTS.HOSTNAME}/api/users/log-user`,
                //     { email:userName}
                // );
                console.log(shippingDetails);
            } catch (e) {
                setError(e.message);
                console.log(e);
            }
            setLoading(false);
        }
    }

    return (
        <>
            <Typography variant="h6" gutterBottom>
                Shipping address
            </Typography>
            <form className={classes.form} onSubmit={saveAddress} noValidate>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error ={!!firstNameError}
                            id="firstName"
                            name="firstName"
                            label="First name"
                            fullWidth
                            autoComplete="given-name"
                            inputRef={firstNameRef}
                            onChange={() => {
                                if (firstNameError !== "") setFirstNameError("");
                                if (error !== "") setError("");
                            }}
                            helperText={firstNameError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={!!lastNameError}
                            id="lastName"
                            name="lastName"
                            label="Last name"
                            fullWidth
                            autoComplete="family-name"
                            inputRef={lastNameRef}
                            onChange={() => {
                                if (lastNameError !== "") setLastNameError("");
                                if (error !== "") setError("");
                            }}
                            helperText={lastNameError}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            error={!!addressLine1Error}
                            id="address1"
                            name="address1"
                            label="Address line 1"
                            fullWidth
                            autoComplete="shipping address-line1"
                            inputRef={addressLine1Ref}
                            onChange={() => {
                                if (addressLine1Error !== "") setAddressLine1Error("");
                                if (error !== "") setError("");
                            }}
                            helperText={addressLine1Error}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="address2"
                            required
                            error={!!addressLine2Error}
                            name="address2"
                            label="Address line 2"
                            fullWidth
                            autoComplete="shipping address-line2"
                            inputRef={addressLine2Ref}
                            onChange={() => {
                                if (addressLine2Error !== "") setAddressLine2Error("");
                                if (error !== "") setError("");
                            }}
                            helperText={addressLine2Error}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="telephone"
                            required
                            error ={!!telephoneError}
                            name="telephone"
                            label="Telephone"
                            fullWidth
                            autoComplete="telephone"
                            inputRef={telephoneRef}
                            onChange={() => {
                                if (telephoneError !== "") setTelephoneError("");
                                if (error !== "") setError("");
                            }}
                            helperText={telephoneError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={!!cityError}
                            id="city"
                            name="city"
                            label="City"
                            fullWidth
                            autoComplete="shipping address-level2"
                            inputRef={cityRef}
                            onChange={() => {
                                if (cityError !== "") setCityError("");
                                if (error !== "") setError("");
                            }}
                            helperText={cityError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={!!provinceError}
                            id="province"
                            name="province"
                            label="Province"
                            fullWidth
                            autoComplete="shipping province"
                            inputRef={provinceRef}
                            onChange={() => {
                                if (provinceError !== "") setProvinceError("");
                                if (error !== "") setError("");
                            }}
                            helperText={provinceError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={!!postalCodeError}
                            id="zip"
                            name="zip"
                            label="Zip / Postal code"
                            fullWidth
                            autoComplete="shipping postal-code"
                            inputRef={postalCodeRef}
                            onChange={() => {
                                if (postalCodeError !== "") setPostalCodeError("");
                                if (error !== "") setError("");
                            }}
                            helperText={postalCodeError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={!!countryError}
                            id="country"
                            name="country"
                            label="Country"
                            fullWidth
                            autoComplete="country"
                            inputRef={countryRef}
                            onChange={() => {
                                if (countryError !== "") setCountryError("");
                                if (error !== "") setError("");
                            }}
                            helperText={countryError}
                        />
                    </Grid>
                </Grid>
                <div className={classes.buttons}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                    >
                        Next
                    </Button>
                </div>
            </form>


        </>
    );
}
export default AddressForm;