import React, { useRef, useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Collapse,
  Container,
  MenuItem,
  TextField,
} from "@material-ui/core";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import ImageIcon from "@material-ui/icons/Image";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import axios from "axios";
import CONSTANTS from "../../../services/constants";
import { makeStyles } from "@material-ui/core/styles";

import { useLocation } from "react-router-dom";
//@ts-ignore
import ReactCrop from "react-image-crop";
//@ts-ignore
import { v4 as uuid } from "uuid";
import Swal from "sweetalert2";
import Typography from "@material-ui/core/Typography";
import { Storage } from "aws-amplify";
import history from "../../../history";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  profile: {
    padding: "2rem",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    border: "2px solid #e6e6e6",
    borderRadius: "5px",
    backgroundColor: "#e6e6e6",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  button: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: "white",
  },
  deleteButton: {
    color: "#c62828",
  },
  textFields: {
    marginBottom: "1rem",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast: any) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const AddProduct = (props: any) => {
  const classes = useStyles();

  const productNameRef = useRef<HTMLInputElement>();
  const productDescriptionRef = useRef<HTMLInputElement>();
  const productPriceRef = useRef<HTMLInputElement>();
  const productQuantityRef = useRef<HTMLInputElement>();
  const productCategoryRef = useRef<HTMLInputElement>();

  const [productNameError, setProductNameError] = useState("");
  const [productDescriptionError, setProductDescriptionError] = useState("");
  const [productPriceError, setProductPriceError] = useState("");
  const [productQuantityError, setProductQuantityError] = useState("");
  const [productCategoryError, setProductCategoryError] = useState("");

  const [productImage, setProductImage] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1 });
  const [results, setResults] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [empty, setEmpty] = useState(true);

  const [loading, setLoading] = useState(false);
  const [cropping, setCropping] = useState(false);
  const [error, setError] = useState("");

  const categories = props.categories;

  const addProduct = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    let productName = "",
      productDescription = "",
      productPrice = "",
      productQuantity = "",
      productCategory = "";

    if (productNameRef.current) productName = productNameRef.current.value;
    if (productDescriptionRef.current)
      productDescription = productDescriptionRef.current.value;
    if (productPriceRef.current) productPrice = productPriceRef.current.value;
    if (productQuantityRef.current)
      productQuantity = productQuantityRef.current.value;
    if (productCategoryRef.current)
      productCategory = productCategoryRef.current.value;

    if (productName === "") setProductNameError("Cannot be empty");
    else if (productDescription === "")
      setProductDescriptionError("Cannot be empty");
    else if (productPrice === "") setProductPriceError("Cannot be empty");
    else if (parseInt(productPrice) < 50)
      setProductPriceError("Price should be grater than LKR 50");
    else if (productQuantity === "") setProductQuantityError("Cannot be empty");
    else {
      // @ts-ignore
      if (productCategory === 0)
        setProductCategoryError("Select product category");
      else if (results.length === 0) setError("");
      else {
        try {
          const imageURLs = [];
          for (const image of results) {
            const res = await Storage.put(productName + uuid(), image);
            //@ts-ignore
            imageURLs.push(res.key);
          }
          const productDetails = {
            productName: productName,
            productDescription: productDescription,
            unitPrice: productPrice,
            quantity: productQuantity,
            categoryId: productCategory,
            productImages: imageURLs,
          };
          axios
            .post(
              `${CONSTANTS.HOSTNAME}/api/products/create-product`,
              productDetails
            )
            .then((res) => {
              Toast.fire({
                icon: "success",
                title: "Product Added Successfully",
              });
              // console.log(res);
              history.push({
                pathname: `/adminpanel/manage-products/view-product`,
                //@ts-ignore
                state: { id: res.data.data.productId },
              });
            });
        } catch (e) {
          setError(e.message);
        }
      }
    }
    setLoading(false);
  };

  const handleProductImage = async (event: any) => {
    if (event.target.files.length != 0) {
      // @ts-ignore
      setProductImage(URL.createObjectURL(event.target.files[0]));
      setCropping(true);
    }
  };

  const getCroppedImg = () => {
    const canvas = document.createElement("canvas");
    // @ts-ignore
    const scaleX = image.naturalWidth / image.width;
    // @ts-ignore
    const scaleY = image.naturalHeight / image.height;
    // @ts-ignore
    canvas.width = crop.width;
    // @ts-ignore
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    // @ts-ignore
    ctx.drawImage(
      // @ts-ignore
      image,
      // @ts-ignore
      crop.x * scaleX,
      // @ts-ignore
      crop.y * scaleY,
      // @ts-ignore
      crop.width * scaleX,
      // @ts-ignore
      crop.height * scaleY,
      0,
      0,
      // @ts-ignore
      crop.width,
      // @ts-ignore
      crop.height
    );
    try {
      const croppedImageDataURL = canvas.toDataURL("image/*");
      const urlDetails = croppedImageDataURL.split(",");
      // @ts-ignore
      const mime = urlDetails[0].match(/:(.*?);/)[1];
      const bstr = atob(urlDetails[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      // @ts-ignore
      setResults([...results, new File([u8arr], uuid(), { type: mime })]);
      // @ts-ignore
      setPreviewImages([...previewImages, croppedImageDataURL]);
      setProductImage(null);
      setCropping(false);
      setEmpty(false);
    } catch (e) {
      // handle this with sweet-alert
      console.log("You need to crop image");
    }
  };

  const deleteImage = (index: number) => {
    let temp = results;
    temp.splice(index, 1);
    if (temp.length === 0) setEmpty(true);
    setResults(temp);
    temp = previewImages;
    temp.splice(index, 1);
    setPreviewImages(temp);
  };

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <form className={classes.profile} onSubmit={addProduct} noValidate>
        <TextField
          error={!!productNameError}
          required
          fullWidth
          label="Product Name"
          autoFocus
          className={classes.textFields}
          inputRef={productNameRef}
          onChange={() => {
            if (productNameError !== "") setProductNameError("");
            if (error !== "") setError("");
          }}
          helperText={productNameError}
        />
        <TextField
          error={!!productDescriptionError}
          required
          fullWidth
          label="Product Description"
          className={classes.textFields}
          multiline
          inputRef={productDescriptionRef}
          onChange={() => {
            if (productDescriptionError !== "") setProductDescriptionError("");
            if (error !== "") setError("");
          }}
          helperText={productDescriptionError}
        />
        <TextField
          error={!!productPriceError}
          required
          fullWidth
          label="Product Price"
          className={classes.textFields}
          inputRef={productPriceRef}
          onChange={() => {
            if (productPriceError !== "") setProductPriceError("");
            if (error !== "") setError("");
          }}
          helperText={productPriceError}
        />
        <TextField
          error={!!productQuantityError}
          required
          fullWidth
          label="Product Quantity"
          className={classes.textFields}
          inputRef={productQuantityRef}
          onChange={() => {
            if (productQuantityError !== "") setProductQuantityError("");
            if (error !== "") setError("");
          }}
          helperText={productQuantityError}
        />
        <TextField
          error={!!productCategoryError}
          required
          fullWidth
          select
          label=""
          className={classes.textFields}
          inputRef={productCategoryRef}
          onChange={() => {
            if (productCategoryError !== "") setProductCategoryError("");
            if (error !== "") setError("");
          }}
          helperText={productCategoryError}
          defaultValue={0}
        >
          <MenuItem value={0} disabled={true}>
            Select category
          </MenuItem>
          {categories.map((category: any) => (
            <MenuItem
              key={category["categoryName"]}
              value={category["categoryId"]}
            >
              {category["categoryName"]}
            </MenuItem>
          ))}
        </TextField>
        <Container maxWidth={"sm"}>
          <div>
            <Collapse in={!cropping && results.length < 5}>
              <Box mt={2} ml={-4} pb={2}>
                <Button
                  variant="contained"
                  component="label"
                  color={"secondary"}
                >
                  <ImageIcon />
                  &nbsp;
                  {`Select product images ${results.length}/5`}
                  <input type="file" hidden onChange={handleProductImage} />
                </Button>
              </Box>
            </Collapse>
            <div>
              <Collapse in={!!productImage}>
                <Box mt={2}>
                  <ReactCrop
                    style={{ maxWidth: "100%" }}
                    src={productImage}
                    onImageLoaded={setImage}
                    crop={crop}
                    onChange={setCrop}
                  />
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={getCroppedImg}
                  >
                    crop
                  </Button>
                </Box>
              </Collapse>
            </div>
          </div>
        </Container>
        <Collapse in={!empty}>
          <Box mt={2}>
            <GridList className={classes.gridList} cols={2.5}>
              {previewImages.map((image, index) => (
                <GridListTile key={index}>
                  <img src={image} alt={`Image ${index + 1}`} />
                  <GridListTileBar
                    title={`Image ${index + 1}`}
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                    }}
                    actionIcon={
                      <IconButton
                        aria-label={`star ${index}`}
                        onClick={() => deleteImage(index)}
                      >
                        <DeleteIcon className={classes.deleteButton} />
                      </IconButton>
                    }
                  />
                </GridListTile>
              ))}
            </GridList>
          </Box>
        </Collapse>
        <div className="btns">
          <Button
            style={{ marginRight: "5%" }}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save Changes
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddProduct;
