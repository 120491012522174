import React, { useEffect, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Backdrop,
  CircularProgress,
  Collapse,
  Container,
  Grid,
  TextField,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Auth } from "aws-amplify";
import axios from "axios";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import CONSTANTS from "../../../services/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    padding: "2rem",
    marginLeft: "25%",
    width: "50%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    border: "2px solid #e6e6e6",
    borderRadius: "5px",
    backgroundColor: "#e6e6e6",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  margin: {
    borderRadius: "50px",
    padding: "1%",
    marginRight: "11.5%",
    marginTop: "1%",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  conroot: {
    marginBottom: "2.75%",
  },
  container: {
    maxHeight: 440,
    tableLayout: "fixed",
  },
  paper: {
    marginTop: "2rem",
    width: "80%",
    marginLeft: "10%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formLabel: {
    marginBottom: "2rem",
  },
  formInputs: {
    marginBottom: "1rem",
  },
}));

const columns = [
  { id: "email", label: "Email", align: "left" },
  { id: "firstName", label: "First Name", align: "left" },
  { id: "lastName", label: "Last Name", align: "left" },
  { id: "role", label: "Role", align: "left" },
];

const ManageUsers = (props: any) => {
  const classes = useStyles();

  const firstNameRef = useRef<HTMLInputElement>();
  const lastNameRef = useRef<HTMLInputElement>();
  const emailRef = useRef<HTMLInputElement>();

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  const [creatingUser, setCreatingUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleChangePage = async (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${CONSTANTS.HOSTNAME}/api/users/all-users`);
      const fetchedUsers = res.data.data;
      fetchedUsers.forEach((user: any, index: number) => {
        if (user.role === "ADMIN") fetchedUsers[index].role = "Readonly admin";
        else if (user.role === "SUPER")
          fetchedUsers[index].role = "Super admin";
        else fetchedUsers[index].role = "User";
      });
      setUsers(fetchedUsers);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const generatePassword = () => {
    return Math.random().toString(36).slice(-8);
  };

  const createAdmin = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (firstNameRef.current && lastNameRef.current && emailRef.current) {
      let password = "";
      while (!password.match("^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$"))
        password = generatePassword();
      try {
        const { user } = await Auth.signUp({
          username: emailRef.current.value,
          password,
          attributes: {
            name: firstNameRef.current.value + lastNameRef.current.value,
            phone_number: "+94712345678",
          },
        });
        await axios.post("http://localhost:5000/api/users/create-user", {
          email: emailRef.current.value,
          role: "ADMIN",
          firstName: firstNameRef.current.value,
          lastName: lastNameRef.current.value,
          password,
        });
        // lets add a toast here!
        await fetchUsers();
        setCreatingUser(false);
      } catch (error) {
        console.log(error);
        setError(error.message);
      }
    }
    setLoading(false);
  };

  return (
    <div className={classes.root}>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <Grid container direction={"column"} alignItems="flex-end">
        <Button
          color={creatingUser ? "secondary" : "primary"}
          variant="contained"
          disabled={props.role}
          className={classes.margin}
          onClick={() => setCreatingUser(!creatingUser)}
        >
          {creatingUser ? (
            <CancelIcon className={classes.extendedIcon} />
          ) : (
            <AddCircleIcon className={classes.extendedIcon} />
          )}
          {creatingUser ? "Cancel" : "New Admin"}
        </Button>
      </Grid>
      <Container maxWidth={"lg"} className={classes.conroot}>
        <Collapse in={creatingUser}>
          <>
            <form className={classes.form} noValidate onSubmit={createAdmin}>
              <Typography variant="h4" className={classes.formLabel}>
                New readonly admin
              </Typography>
              <Grid container spacing={2} className={classes.formInputs}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={!!firstNameError}
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    inputRef={firstNameRef}
                    helperText={firstNameError}
                    onChange={() => setFirstNameError("")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={!!lastNameError}
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                    inputRef={lastNameRef}
                    helperText={lastNameError}
                    onChange={() => setLastNameError("")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={!!emailError}
                    variant="outlined"
                    required
                    fullWidth
                    label="Email Address"
                    autoComplete="email"
                    inputRef={emailRef}
                    helperText={emailError}
                    onChange={() => setEmailError("")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Role"
                    value="Readonly admin"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
              <Collapse in={!!error}>
                <Alert severity="error">{error}</Alert>
              </Collapse>
              <Grid container direction="column" xs={12} alignItems="flex-end">
                <Grid item xs={8}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        </Collapse>
        <Collapse in={!creatingUser}>
          <Paper className={classes.paper}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead style={{ backgroundColor: "black" }}>
                  <TableRow>
                    {columns.map((column) => (
                      // @ts-ignore
                      <TableCell key={column.id} align={column.align}>
                        <b>{column.label}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow>
                          {columns.map((column) => {
                            //@ts-ignore
                            let value = row[column.id];
                            return (
                              <TableCell key={column.id}>
                                {value}
                                {/* {column.id == "edit" && (
                                  <>
                                    <Button
                                      color="primary"
                                      onClick={(e) =>
                                        console.log("View this user")
                                      }
                                    >
                                      View
                                    </Button>
                                  </>
                                )} */}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Collapse>
      </Container>
    </div>
  );
};

export default ManageUsers;
