import React, { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import history from "../../history";
import {
  Avatar,
  Container,
  Backdrop,
  CircularProgress,
  TextField,
  Grid,
  Button,
  CssBaseline,
  Typography,
  Collapse,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import CONSTANTS from "../../services/constants";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignUp = (props: any) => {
  const classes = useStyles();

  const firstNameRef = useRef<HTMLInputElement>();
  const lastNameRef = useRef<HTMLInputElement>();
  const emailRef = useRef<HTMLInputElement>();
  const passwordRef = useRef<HTMLInputElement>();
  const confirmPasswordRef = useRef<HTMLInputElement>();

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState({
    error: false,
    message:
      "Passwords should contain at least 8 characters of numbers and letters",
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const getFormDetails = () => {
    let firstName = "",
      lastName = "",
      email = "",
      password = "",
      confirmPassword = "";
    if (firstNameRef.current) firstName = firstNameRef.current.value;
    if (lastNameRef.current) lastName = lastNameRef.current.value;
    if (emailRef.current) email = emailRef.current.value;
    if (passwordRef.current) password = passwordRef.current.value;
    if (confirmPasswordRef.current)
      confirmPassword = confirmPasswordRef.current.value;
    return { firstName, lastName, email, password, confirmPassword };
  };

  const validateFormDetails = (formDetails: any) => {
    console.log("validating");
    let flag = true;
    const { firstName, lastName,email, password, confirmPassword } = formDetails;
    if (firstName === "") {
      flag = false;
      setFirstNameError("Cannot be empty");
    } else if (!/^[a-zA-Z]+$/.test(firstName)) {
      flag = false;
      setFirstNameError("Invalid characters found");
    }
    if (lastName === "") {
      flag = false;
      setLastNameError("Cannot be empty");
    } else if (!/^[a-zA-Z]+$/.test(lastName)) {
      flag = false;
      setLastNameError("Invalid characters found");
    }
    if (email === "") {
      flag = false;
      setEmailError("Cannot be empty");
    } else if (!/^(.+)@(.+){2,}\.(.+){2,}$/.test(email)) {
      flag = false;
      setEmailError("Email is not valid");
    }
    if ((password === "" && confirmPassword === "") || password.length <= 8) {
      flag = false;
      setPasswordError({
        error: true,
        message:
          "Passwords should contain at least 8 characters of numbers and letters",
      });
    } else if (password !== confirmPassword) {
      flag = false;
      setPasswordError({
        error: true,
        message: "Passwords do not match",
      });
    }
    return flag;
  };

  const signup = async (event: any) => {
    event.preventDefault();
    const formDetails = getFormDetails();
    const { firstName, lastName,email, password } = formDetails;
    setLoading(true);
    if (validateFormDetails(formDetails)) {
      console.log("validated");
      const name = firstName + lastName;
      try {
        console.log("signing up");
        const { user } = await Auth.signUp({
          username: email,
          password,
          attributes: {
            phone_number: "+94763615704",
            name,
          },
        });
        const res = await axios.post(
          `${CONSTANTS.HOSTNAME}/api/users/create-user`,
          { email, role: "USER", firstName, lastName }
        );
        console.log(user);
        history.push({
          pathname: "/verify-account",
          state: { email, from: "signup" },
        });
      } catch (error) {
        setError(error.message);
        console.log(error);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form className={classes.form} noValidate onSubmit={signup}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!firstNameError}
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  inputRef={firstNameRef}
                  helperText={firstNameError}
                  onChange={() => setFirstNameError("")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!lastNameError}
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  inputRef={lastNameRef}
                  helperText={lastNameError}
                  onChange={() => setLastNameError("")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!emailError}
                  variant="outlined"
                  required
                  fullWidth
                  label="Email Address"
                  autoComplete="email"
                  inputRef={emailRef}
                  helperText={emailError}
                  onChange={() => setEmailError("")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={passwordError.error}
                  variant="outlined"
                  required
                  fullWidth
                  label="Password"
                  type="password"
                  inputRef={passwordRef}
                  helperText={passwordError.message}
                  onChange={() =>
                    setPasswordError({
                      error: false,
                      message:
                        "Passwords should contain at least 8 characters of numbers and letters",
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={passwordError.error}
                  variant="outlined"
                  required
                  fullWidth
                  label="Confirm password"
                  type="password"
                  inputRef={confirmPasswordRef}
                  onChange={() =>
                    setPasswordError({
                      error: false,
                      message:
                        "Passwords should contain at least 8 characters of numbers and letters",
                    })
                  }
                  helperText=" "
                />
              </Grid>
            </Grid>
            <Collapse in={!!error}>
              <Alert severity="error">{error}</Alert>
            </Collapse>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign Up
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
};

export default SignUp;
