import React from "react";
import { 
    Button,
    Container, 
    createStyles, 
    Grid, 
    Link, 
    makeStyles, 
    Theme 
} from "@material-ui/core";
import notFoundImage from "../../Assets/Images/404.png";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContainer: {
            border: "2px solid #e6e6e6",
            backgroundColor: "#e6e6e6",
            borderRadius: "5px",
            margin: "5rem 0 -10rem 0",
        },
        mainImage : {
            marginTop:"2rem",
            marginLeft: "35%",
            width: "30%",
            marginBottom: "-2%"
        },
        mainText: {
            color: "rgba(0, 0, 0, 0.75)",
            fontSize: "2vw",
            marginLeft: "37%"
        },
        homeButton: {
            width: "16%",
            marginLeft: "42%",
            marginBottom: "2rem"
        }
    })
);

const NotFoundPage = () => {

    const classes = useStyles();
    
    return(
        <>
            <Container maxWidth={"lg"}>
                <Grid container className={classes.pageContainer}>
                    <Grid xs={12}>
                        <img src={notFoundImage} alt="Couldn't load the image" className={classes.mainImage}/>
                    </Grid>
                    <Grid xs={12}>
                        <h1 className={classes.mainText}>PAGE NOT FOUND</h1>
                    </Grid>
                    <Grid xs={12}>
                        <Link href="/"><Button variant="contained" color="primary" className={classes.homeButton}>Back to Home</Button></Link>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}


//@ts-ignore
export default NotFoundPage;