/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:e5834b4b-3da0-4793-b83d-2aa226487ce5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_axMEXbxRP",
    "aws_user_pools_web_client_id": "15rfnl5fef6faqihpvthaf54v2",
    "oauth": {},
    "aws_user_files_s3_bucket": "webshoppingcartfront0c23f5e2ea8146b49f40912721f220418-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
