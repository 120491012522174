import React, {useEffect} from "react";
import {
    fade,
    makeStyles,
    Theme,
    createStyles,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import {Link} from "@material-ui/core";
import {Auth} from "aws-amplify";
import history from "../../../history";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginLeft: theme.spacing(2),
            marginTop: "10%",
            fontSize: "17px",
        },
        list: {
            width: 250,
        },
        fullList: {
            width: "auto",
        },
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        iconBut: {
            color: "white",
        },
        title: {
            display: "none",
            [theme.breakpoints.up("sm")]: {
                display: "block",
            },
            color: "white",
        },

        search: {
            position: "relative",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            "&:hover": {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(3),
                width: "auto",
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        inputRoot: {
            color: "inherit",
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create("width"),
            width: "100%",
            [theme.breakpoints.up("md")]: {
                width: "20ch",
            },
        },
        sectionDesktop: {
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "flex",
            },
        },
        sectionMobile: {
            display: "flex",
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
    })
);

export const AdminMenu = (props: any) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        left: false,
    });
    const [isAuthenticated, setAuthenticated] = React.useState(props.isAuthenticated);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);

    const handleOpenCartPage = () => {
        //history.push('/user-cart');
    };
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleOpenProfilePage = () => {
        handleMenuClose();
        //  history.push('/profile');
    };

    const handleOpenHome = () => {
        //  history.push('/home');
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        //handleMobileMenuClose();
    };
    const authenticate = async () => {
        try {
            if(localStorage.getItem("userID") != null){
                setAuthenticated(true);
            }else{
                setAuthenticated(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        authenticate().then(()=>{
            console.log("AdminAuthenticated!");
           // console.log(authenticated);
        });
    }, []);
    const guestLinks = (
        <Typography className={classes.root}>
            <Link href="/login" color="inherit" style={{textDecoration: "none"}}>
                Login
            </Link>
        </Typography>
    );

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: "top", horizontal: "right"}}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem
                onClick={async () => {
                    await Auth.signOut();
                    localStorage.removeItem("userID");
                    history.push({
                        pathname: `/login`,
                    });
                }}
            >
                Logout
            </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.grow}>
            <AppBar position="static" style={{backgroundColor: "#214003"}}>
                <Toolbar>
                    <Typography className={classes.title} variant="h6" noWrap>
                        <Link
                            href=""
                            onClick={handleOpenHome}
                            color="inherit"
                            style={{textDecoration: "none"}}
                        >
                            Admin Panel
                        </Link>
                    </Typography>
                    <div className={classes.grow}/>
                    <div className={classes.sectionDesktop}>
                        {isAuthenticated ? (
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                                className={classes.iconBut}
                            >
                                <AccountCircle/>
                            </IconButton>
                        ) : (
                            guestLinks
                        )}
                    </div>
                </Toolbar>
            </AppBar>
            {renderMenu}
        </div>
    );
};

export default AdminMenu;
