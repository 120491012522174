import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress, Container, Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import TeaImage from "../../Assets/Images/Tea.jpg";
import history from "../../history";
import CategoryContainer from "./categoryContainer";
import CONSTANTS from "../../services/constants";

import { Storage } from "aws-amplify";
const axios = require("axios").default;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    cardPadding: {
      paddingTop: "40px",
    },
    media: {
      height: "250px",
      width: "250px", // 16:9,
      borderRadius: "7%",
      cursor: "pointer",
      filter: "brightness(55%)",
      border: "none",
    },
    carouselSetStyle: {
      margin: "1rem",
    },
    mediaText: {
      position: "absolute",
      color: "white",
      top: "50%",
      left: "50%",
      fontSize: "250%",
      transform: "translate(-50%, -50%)",
      fontFamily: "Marker Felt, fantasy",
      textShadow: "6px 6px 5px #000000",
      textAlign: "center",
      cursor: "pointer",
    },
    categoryContainer: {
      border: "2px solid #e6e6e6",
      backgroundColor: "#e6e6e6",
      borderRadius: "5px",
      margin: "5rem 0",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

const handleCategory = (name: any) => (event: any) => {
  history.push(`/category/${name}`);
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const Item = (props: any) => {
  return (
    <div onClick={handleCategory(props.item.categoryName)}>
      {props.item.categoryImage !== null ? (
        // @ts-ignore
        <img
          className={props.class.media}
          src={props.item.categoryImage}
          alt="Image couldn't load"
        />
      ) : (
        <img
          className={props.class.media}
          src={TeaImage}
          alt="Image couldn't load"
        />
      )}
      <div className={props.class.mediaText}>{props.item.categoryName}</div>
    </div>
  );
};

const Homepage = (props: any) => {
  const classes = useStyles();
  const [allCategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    callCategory();
    // console.log(allCategories);
  }, []);

  const callCategory = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${CONSTANTS.HOSTNAME}/api/categories/get-all-categories`
      );
      const categories = res.data.data;
      for (let i = 0; i < categories.length; i++) {
        categories[i].categoryImage = await Storage.get(
          categories[i].categoryImage
        );
      }
      setAllCategories(categories);
    } catch (e) {}
    setLoading(false);
  };

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <Container maxWidth={"lg"}>
        <Grid
          item
          xs={12}
          className={classes.cardPadding}
          container
          spacing={1}
        >
          {allCategories.map((categoryRow, i) => (
            <CategoryContainer key={i} categoryRow={categoryRow} />
          ))}
        </Grid>
        <Grid item xs={12} className={classes.categoryContainer}>
          <div className={classes.carouselSetStyle}>
            <Carousel
              swipeable={true}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              customTransition="transform 1000ms ease-in-out"
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="react-multi-carousel-dot-list"
              itemClass="carousel-item-padding-50-px"
              centerMode={true}
            >
              {allCategories.map((item, i) => (
                <Item key={i} item={item} class={classes} />
              ))}
            </Carousel>
          </div>
        </Grid>
      </Container>
    </>
  );
};

// @ts-ignore
export default Homepage;
