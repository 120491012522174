import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Backdrop,
  CircularProgress,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Collapse,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import "./dashboard.css";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EmojiFoodBeverageIcon from "@material-ui/icons/EmojiFoodBeverage";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import CategoryIcon from "@material-ui/icons/Category";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { Line } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import CONSTANTS from "../../../services/constants";



const optionsLine = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  profile: {
    marginLeft: "auto",
    marginRight: "auto",
    margin: theme.spacing(1),
    width: "40ch",
    display: "block",
    border: "2px solid #e6e6e6",
    borderRadius: "5px",
    backgroundColor: "#e6e6e6",
    padding: "3rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    maxHeight: "100px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  margin: {
    borderRadius: "50px",
    padding: "1%",
    marginRight: "11.5%",
    marginTop: "1%",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  dashboard: {
    marginTop: "2rem",
    // marginLeft: "5rem",
  },
  dashboardIcon: {
    marginTop: "1rem",
    marginLeft: "45%",
  },
  chrt: {
    marginTop: "2rem",
    marginBottom: "2rem",
    border: "solid 0.5px", 
    borderRadius: "10px", 
    borderColor: "#A8A8A8",
    boxShadow: "2px 5px 20px #A8A8A8",
  }
}));

const Dashboard = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState("");
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [monthlySales, setMonthlySales] = useState([]);
  const [state, setState] = useState({
    users: "",
    admins: "",
    products: "",
    categories: "",
    orders: "",
  });
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: any) => {
    setValue(index);
  };

  const handleOpenSettingsPage = () => {
    // history.push('/settings');
  };

  const fetchProductsInFunction = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${CONSTANTS.HOSTNAME}/api/products/get-no-of-products-on-each-category`
      );
      setCategoryProducts(res.data.data);
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  const fetchMonthlySales = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${CONSTANTS.HOSTNAME}/api/orders/get-monthly-sales`
      );
      setMonthlySales(res.data.data);
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCount();
    fetchProductsInFunction();
    fetchMonthlySales();
  }, []);

  const getCount = async () => {
    try {
      const res = await axios.get(`${CONSTANTS.HOSTNAME}/api/common/count-all`);
      console.log(res.data.data);
      setState({
        ...state,
        users: res.data.data.users,
        admins: res.data.data.admins,
        products: res.data.data.products,
        categories: res.data.data.categories,
        orders: res.data.data.orders,
      });
    } catch (e) {
      console.log(e);
    }
  };

  let catLabels: any[] = [];
  let catData: any[] = [];
  let graphColors: any[] = [];
  let graphFillColors: any[] = [];
  let graphBorderColors: any[] = [];
  let backColor = `, 0.5)`;
  let bordColor = `, 1)`;

  const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

  const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()}`;
  

  // @ts-ignore
  categoryProducts.map((element: any) => {
    catLabels.push(element.catName);
    catData.push(element.prodCount);
    graphColors.push(randomRGB());
  });

  graphColors.map((element: any) => {
    graphFillColors.push(element.concat(backColor));
  });

  graphColors.map((element: any) => {
    graphBorderColors.push(element.concat(bordColor));
  });

  const productsInCategory = {
    labels: catLabels,
    datasets: [
      {
        label: "Number of Products in each Category",
        data: catData,
        backgroundColor: graphFillColors,
        borderColor: graphBorderColors,  
        borderWidth: 1,
      },
    ],
  };

  let salLabels: any[] = [];
  let salData: any[] = [];

  monthlySales.map((element: any) => {
    salLabels.push(element.salMonth);
    salData.push(element.salAmount);
  });

  const dataLine = {
    labels: salLabels,
    datasets: [
      {
        label: "Sales",
        data: salData,
        fill: false,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.2)",
      },
    ],
  };

  return (
    <div className={classes.root} style={{alignContent: "center"}}>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <Grid container direction={"column"} alignItems="flex-end">
        <Button
          color={editing ? "secondary" : "primary"}
          variant="contained"
          className={classes.margin}
          onClick={() => setEditing(!editing)}
        >
          {editing ? (
            <CancelIcon className={classes.extendedIcon} />
          ) : (
            <AddCircleIcon className={classes.extendedIcon} />
          )}
          {editing ? "Cancel" : "Edit Profile"}
        </Button>
      </Grid>
      <Container maxWidth={"lg"} style={{alignContent: "center"}}>
        <Collapse in={editing}>
          <>
            <form className={classes.profile} noValidate autoComplete="off">
              <FormControl>
                <InputLabel htmlFor="my-input">First name</InputLabel>
                <Input id="my-input" aria-describedby="my-helper-text" />
                <FormHelperText id="my-helper-text">
                  Enter your first name.
                </FormHelperText>
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="my-input">Last name</InputLabel>
                <Input id="my-input" aria-describedby="my-helper-text" />
                <FormHelperText id="my-helper-text">
                  Enter your last name.
                </FormHelperText>
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="my-input">Email address</InputLabel>
                <Input id="my-input" aria-describedby="my-helper-text" />
                <FormHelperText id="my-helper-text">
                  We'll never share your email.
                </FormHelperText>
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="my-input">Telephone number</InputLabel>
                <Input id="my-input" aria-describedby="my-helper-text" />
                <FormHelperText id="my-helper-text">
                  Enter your telephone number.
                </FormHelperText>
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="my-input">Admin Role</InputLabel>
                <Input id="my-input" aria-describedby="my-helper-text" />
                <FormHelperText id="my-helper-text">
                  Enter the admin role.
                </FormHelperText>
              </FormControl>
              <div className="btns">
                <Button
                  style={{ marginRight: "5%" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save Changes
                </Button>
                <Button variant="contained">Cancel</Button>
              </div>
            </form>
          </>
        </Collapse>
        <Collapse in={!editing}>
          <div className={classes.dashboard} >
            <Grid container spacing={3} alignContent="center" style={{marginLeft: "5%"}}>
              <Grid item xs={6} sm={2} alignContent="center" >
                <Card className={classes.root}>
                  <div className={classes.dashboardIcon} > 
                    <AccountCircleIcon />
                  </div>
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="center"
                    >
                      Total Users
                    </Typography>
                    <Typography gutterBottom variant="h5" component="h2" align="center">
                      {state.users}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Card className={classes.root}>
                  <div className={classes.dashboardIcon}>
                    <SupervisorAccountIcon />
                  </div>
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="center"
                    >
                      Total Admins
                    </Typography>
                    <Typography gutterBottom variant="h5" component="h2" align="center">
                      {state.admins}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Card className={classes.root}>
                  <div className={classes.dashboardIcon}>
                    <EmojiFoodBeverageIcon />
                  </div>
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="center"
                    >
                      Total Products
                    </Typography>
                    <Typography gutterBottom variant="h5" component="h2" align="center">
                      {state.products}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Card className={classes.root}>
                  <div className={classes.dashboardIcon}>
                    <CategoryIcon />
                  </div>
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="center"
                    >
                      Total Categories
                    </Typography>
                    <Typography gutterBottom variant="h5" component="h2" align="center">
                      {state.categories}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Card className={classes.root}>
                  <div className={classes.dashboardIcon}>
                    <LocalShippingIcon />
                  </div>
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="center"
                    >
                      Total Orders
                    </Typography>
                    <Typography gutterBottom variant="h5" component="h2" align="center">
                      {state.orders}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={4} direction="column" alignContent="center">
              <Grid item xs={6} className={classes.chrt}>
                <>
                  <div>
                    <Typography variant="h5"  align="center">
                      Sales relevant to the month
                    </Typography>
                  </div>
                  <Line data={dataLine} options={optionsLine} />
                </>
              </Grid>
              <Grid item xs={6} className={classes.chrt}>
                <>
                  <div>
                    <Typography variant="h5"  align="center">
                      Categories by the number of products
                    </Typography>
                  </div>
                  <Pie data={productsInCategory} />
                </>
              </Grid>
            </Grid>
          </div>
        </Collapse>
      </Container>
    </div>
  );
};

export default Dashboard;
