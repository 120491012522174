import React, { useEffect, useState } from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import {
  Grid,
  Fab,
  Button,
  Snackbar,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Picture from "../../Assets/Images/DSCN0337.jpg";
import PulaPic from "../../Assets/Images/Pulsara.jpg";
import PriceTag from "@material-ui/icons/AttachMoney";
import QuantityTag from "@material-ui/icons/ConfirmationNumber";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import history from "../../history";
import axios from "axios";
import CONSTANTS from "../../services/constants";
import Swal from "sweetalert2";
import { ReactPhotoCollage } from "react-photo-collage";
import { Storage } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  outer: {
    display: "table",
    marginTop: "3rem",
    height: "100%",
    width: "100%",
  },
  middle: {
    display: "table-cell",
    verticalAlign: "middle",
  },
  inner: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%",
  },
  root: {
    padding: "1rem",
    backgroundColor: "#e6e6e6",
  },
  media: {
    height: "100%",
    width: "100%", // 16:9,
    cursor: "pointer",
    maxWidth: "200px",
    maxHeight: "185px",
    marginTop: "5%",
    borderRadius: "5px",
  },
  actionMedia: {
    marginLeft: "10%",
    marginBottom: "10%",
  },
  title: {
    marginTop: "1rem",
    marginLeft: "2rem",
    paddingBottom: "1%",
    textAlign: "left",
  },
  description: {
    padding: "1%",
    display: "flex",
    alignItems: "center",
  },
  buttonsRight: {
    paddingRight: "5%",
    float: "right",
    alignItems: "center",
    display: "flex",
  },
  buttonsLeft: {
    paddingLeft: "5%",
    float: "left",
    alignItems: "center",
    display: "flex",
  },
  buttonBig: {
    margin: "1%",
    minWidth: "130px",
  },
  buttonSmall: {
    margin: "1%",
    width: "53px"
  },
  buttonText: {
    marginLeft: "10%",
    marginRight: "10%",
  },
  buttonSet: {
    marginTop: "2%",
  },
  text: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  photoCollage:{
    position:"absolute"
  }
}));

// @ts-ignore
const ItemCardView = ({ match, validateAuth, ...rest }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    productId: "",
    productName: "",
    productDescription: "",
    productPrice: "",
    productQuantity: "",
    productCategory: "",
    selectedQuantity: 1,
    productImages: [],
  });
  const [productImages, setProductImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [setting, setSetting] = useState(null);
  useEffect(() => {}, [match]);

  useEffect(() => {
    //@ts-ignore
    callProduct();
    // console.log(productImages);
  }, []);

  const callProduct = async () => {
    setLoading(true);
    try {
      // @ts-ignore
      const product = await axios.get(
        `${CONSTANTS.HOSTNAME}/api/products/get-product/${match.params.productId}`
      );
      const images = product.data.data.productImages;
      const resultImages = [];
      console.log(images);
      //setProductImages([]);
      for (let i = 0; i < images.length; i++) {
        resultImages.push({
          source: await Storage.get(images[i].imageUrl),
        });
      }
      // console.log(resultImages);
      // @ts-ignore
      setProductImages(resultImages);

      setSetting({
        // @ts-ignore
        width: "200px",
        height: ["200px", "200px"],
        layout: [1],
        photos: resultImages,
        showNumOfRemainingPhotos: true,
      });
      setState({
        ...state,
        productId: product.data.data.productId,
        productName: product.data.data.productName,
        productDescription: product.data.data.productDescription,
        productPrice: product.data.data.unitPrice,
        productQuantity: product.data.data.quantity,
        productCategory: product.data.data.category,
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  const orderDetails = [
    {
      userId: localStorage.getItem("userID"),
      productId: state.productId,
      productName: state.productName,
      productDescription: state.productDescription,
      productPrice: state.productPrice,
      selectedQuantity: state.selectedQuantity,
    },
  ];

  const handleOpenCheckout = async () => {
    try {
      //console.log(orderDetails);
      // const res = await axios.post(`${CONSTANTS.HOSTNAME}/api/order/create-order`, (orderDetails));
      // console.log(res);
      if (await validateAuth("test")) {
        history.push({
          pathname: "/checkout",
          state: { orderDetails: orderDetails },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleQuantity = (event: any, key: any) => {
    switch (key) {
      case "remove":
        setState({
          ...state,
          selectedQuantity: state.selectedQuantity - 1,
        });
        break;
      case "add":
        setState({
          ...state,
          selectedQuantity: state.selectedQuantity + 1,
        });
        break;
    }
  };

  const CartItemDetails = {
    quantity: state.selectedQuantity,
    totalCost: state.selectedQuantity * parseInt(state.productPrice),
    userId: localStorage.getItem("userID"),
    productId: state.productId,
  };

  const handleAddToCart = async (event: any) => {
    if (await validateAuth()) {
      try {
        console.log(state.productImages);
        const item = await axios.post(
          `${CONSTANTS.HOSTNAME}/api/cart/add-item`,
          CartItemDetails
        );
        console.log(item);
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Item added to cart",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (e) {
        await Swal.fire({
          position: "center",
          icon: "error",
          title: "Item already added to cart",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <div className={classes.outer}>
        <div className={classes.middle}>
          <div className={classes.inner}>
            <Card className={classes.root}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      variant="h4"
                      component="h2"
                    >
                      {state.productName}
                    </Typography>
                    <hr />
                  </CardContent>
                </Grid>
                <Grid item xs={1}/>
                <Grid item xs={3}>
                  <div className={classes.photoCollage}>
                  {/* <img className={classes.media} src={PulaPic} alt="dfdf" /> */}
                  {/*@ts-ignore*/}
                  {setting && <ReactPhotoCollage {...setting} />}
                  </div>
                </Grid>
                <Grid item xs={1}/>
                <Grid item xs={7}>
                  <CardContent>
                    <Typography
                      className={classes.description}
                      color="textPrimary"
                      variant="subtitle1"
                      gutterBottom
                    >
                      {state.productDescription}
                    </Typography>
                    <Typography
                      className={classes.description}
                      variant="body1"
                      color="textPrimary"
                    >
                      <Grid container spacing={1} className={classes.text}>
                        <Grid item xs={4}>
                          <PriceTag color="primary" />
                          Price: LKR {state.productPrice}
                        </Grid>
                        <Grid item xs={4}>
                          <QuantityTag color="primary" />
                          Available: {state.productQuantity}
                        </Grid>
                      </Grid>
                    </Typography>
                    <div className={classes.buttonSet}>
                      <div className={classes.buttonsLeft}>
                        {/*// @ts-ignore*/}
                        <Fab
                          size="small"
                          color="secondary"
                          disabled={state.selectedQuantity <= 1}
                          aria-label="add"
                          className={classes.buttonSmall}
                          onClick={(e) => {
                            handleQuantity(e, "remove");
                          }}
                        >
                          <RemoveIcon />
                        </Fab>
                        <Typography
                          className={classes.buttonText}
                          variant="h6"
                          color="textPrimary"
                          component="p"
                        >
                          {state.selectedQuantity}
                        </Typography>
                        <Fab
                          size="small"
                          color="secondary"
                          disabled={
                            state.selectedQuantity >=
                            parseInt(state.productQuantity)
                          }
                          aria-label="remove"
                          className={classes.buttonSmall}
                          onClick={(e) => {
                            handleQuantity(e, "add");
                          }}
                        >
                          <AddIcon />
                        </Fab>
                      </div>
                      <div className={classes.buttonsRight}>
                        <Button
                          variant="contained"
                          color="secondary"
                          aria-label="remove"
                          className={classes.buttonBig}
                          onClick={handleAddToCart}
                        >
                          Add to Cart
                        </Button>
                        <Button
                          onClick={handleOpenCheckout}
                          variant="contained"
                          color="secondary"
                          aria-label="remove"
                          className={classes.buttonBig}
                        >
                          Buy Now
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Grid>
                <Grid item xs={1}/>
              </Grid>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemCardView;
