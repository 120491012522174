import SignUp from "../components/Authentication/signup";
import Homepage from "../components/HomePage/homepage";
import Login from "../components/Authentication/Login";
import ForgotPassword from "../components/Authentication/forgotPassword";
import VerificationTab from "../components/Authentication/verify";
import CartPage from "../components/CartPage/CartPage";
import CheckoutPage from "../components/CheckoutPage/Checkout";
import CategoryPage from "../components/CategoryPage/CategoryPage";
import ProfilePage from "../components/ProfilePage/Profile";
import SettingsPage from "../components/SettingsPage/Settings";
import ItemCardViewPage from "../components/ItemCard/ItemCardView";
import AuthRoute from "./AuthRoute";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";
import { Switch } from "react-router-dom";
import Dashboard from "../components/Adminpanel/Dashboard/dashboard";
import ManageUsers from "../components/Adminpanel/ManageUsers/manageUsers";
import EditProfile from "../components/Adminpanel/EditProfile/editProfile";
import ManageProducts from "../components/Adminpanel/ManageProducts/manageProducts";
import Sales from "../components/Adminpanel/Sales/sales";
import ManageCategories from "../components/Adminpanel/ManageCategories/manageCategories";
import EditProductPage from "../components/Adminpanel/ManageProducts/editProductPage/editproductPage";
import ViewProductPage from "../components/Adminpanel/ManageProducts/ViewProdutPage/viewProductPage";
import ViewCategoryPage from "../components/Adminpanel/ManageCategories/ViewCategoryPage/ViewCategoryPage";
import EditCategoryPage from "../components/Adminpanel/ManageCategories/EditCategoryPage/EditCategoryPage";
import AboutPage from "../components/AboutPage/AboutPage";
import ContactUsPage from "../components/ContactUsPage/ContactUsPage";
import PoliciesPage from "../components/PoliciesPage/PoliciesPage";
import SearchPage from "../components/SearchPage/searchPage";
import NotFoundPage from "../components/NotFoundPage/NotFoundPage";

const Routes = () => {
  return (
    <Switch>
      {/*home one routes*/}
      <AuthRoute exact path="/signup" component={SignUp} />
      <AuthRoute exact path="/forgot-password" component={ForgotPassword} />
      <AuthRoute exact path="/login" component={Login} />
      <AuthRoute exact path="/verify-account" component={VerificationTab} />

      <PublicRoute exact path="/about" component={AboutPage} />
      <PublicRoute exact path="/contact" component={ContactUsPage} />
      <PublicRoute exact path="/policies" component={PoliciesPage} />
      <PublicRoute exact path="/" component={Homepage} />
      <PublicRoute exact path="/search" component={SearchPage} />
      <PublicRoute
        exact
        path="/category/:categoryName"
        component={CategoryPage}
      />
      <PublicRoute
        exact
        path="/item-card-view/:productId"
        component={ItemCardViewPage}
      />

      <PrivateRoute exact path="/user-cart" component={CartPage} />
      <PrivateRoute exact path="/checkout" component={CheckoutPage} />
      <PrivateRoute exact path="/account" component={ProfilePage} />
      <PrivateRoute exact path="/settings" component={SettingsPage} />

      {/* Adminpanel */}
      <AdminRoute exact path="/adminpanel" component={Dashboard} />
      <AdminRoute
        exact
        path="/adminpanel/manage-users"
        component={ManageUsers}
      />
      <AdminRoute
        exact
        path="/adminpanel/edit-profile"
        component={EditProfile}
      />
      <AdminRoute
        exact
        path="/adminpanel/manage-products"
        component={ManageProducts}
      />
      <AdminRoute exact path="/adminpanel/sales" component={Sales} />
      <AdminRoute
        exact
        path="/adminpanel/manage-categories"
        component={ManageCategories}
      />
      <AdminRoute
        exact
        path="/adminpanel/manage-products/edit-product"
        component={EditProductPage}
      />
      <AdminRoute
        exact
        path="/adminpanel/manage-products/view-product"
        component={ViewProductPage}
      />
      <AdminRoute
        exact
        path="/adminpanel/manage-categories/view-category"
        component={ViewCategoryPage}
      />
      <AdminRoute
        exact
        path="/adminpanel/manage-categories/edit-category"
        component={EditCategoryPage}
      />
      <PublicRoute component={NotFoundPage} />

    </Switch>
  );
};
export default Routes;
