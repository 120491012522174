import React, {useEffect, useRef, useState} from "react";
import {Backdrop, CircularProgress, Container, MenuItem, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import axios from "axios";
import CONSTANTS from "../../../../services/constants";
import {makeStyles} from "@material-ui/core/styles";
import {useLocation} from "react-router-dom";
import history from "../../../../history";
import Swal from "sweetalert2";
import Typography from "@material-ui/core/Typography";
// @ts-ignore
import ReactCrop from 'react-image-crop';
import {Storage} from "aws-amplify";

// @ts-ignore
import {v4 as uuid} from "uuid";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginLeft: "35%",
    },
    profile: {
        padding: "2rem",
        marginLeft: "25%",
        width: "50%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        border: "2px solid #e6e6e6",
        borderRadius: "5px",
        backgroundColor: "#e6e6e6"
    },
    button: {
        marginTop: "1rem",
        marginBottom: "1rem"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast: any) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

const EditCategoryPage = () => {
    const classes = useStyles();
    const categoryNameRef = useRef<HTMLInputElement>();

    const [categoryNameError, setcategoryNameError] = useState("");

    const [error, setError] = useState("");
    const [state, setState] = useState({
        categoryId: "",
    });
    let id = "";
    const [categoryImage, setCategoryImage] = useState(null);
    const [image, setImage] = useState(null);
    const [crop, setCrop] = useState({aspect: 1 / 1});
    const [result, setResult] = useState(null);
    const [uploadImg, setUploadImg] = useState(null);
    const location = useLocation();

    const [loading,setLoading] = useState(false);

    useEffect(() => {
        // @ts-ignore
        if (location.state) id = location.state.id;
    }, [location]);

    useEffect(() => {
        callCategory();
    }, []);

    const callCategory = async () => {
        try {
            // @ts-ignore
            const category = await axios.get(// @ts-ignore
                `${CONSTANTS.HOSTNAME}/api/categories/get-category/${location.state.id}`
            );
            const imgURL = await Storage.get(category.data.data.categoryImage);
            //@ts-ignore
            setCategoryImage(imgURL);
            //console.log(imgURL);
            setState({
                ...state,
                categoryId: category.data.data.categoryId,
            });
            // @ts-ignore
            categoryNameRef.current.value = category.data.data.categoryName;
        } catch (e) {
            console.log(e);
        }
    };

    const editCategory = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        let categoryId = "",
            categoryName = "";
        if (categoryNameRef.current) categoryName = categoryNameRef.current.value;
        if (categoryName === "") setcategoryNameError("Cannot be empty");
        else {
            try {
                const img = await Storage.put(categoryName + uuid(), uploadImg);
                const categoryDetails = {
                    categoryId: state.categoryId,
                    categoryName: categoryName,
                    // @ts-ignore
                    categoryImage: img.key,
                };
                // @ts-ignore
                const res = await axios.put(
                    `${CONSTANTS.HOSTNAME}/api/categories/update-category`,
                    categoryDetails
                );
                Toast.fire({
                    icon: "success",
                    title: "Category Updated Successfully",
                }).then(() => {
                    history.push({
                        pathname: `/adminpanel/manage-categories`,
                    });
                });
                console.log(res.data);
                // @ts-ignore
                categoryNameRef.current.value = "";
            } catch (e) {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Something went wrong',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }

        setLoading(false);
    };
    const handleCancel = async (event: any) => {
        event.preventDefault();
        // @ts-ignore
        categoryNameRef.current.value = "";
        history.push({
            pathname: `/adminpanel/manage-categories`,
        });
    };
    const handleImage = async (event: any) => {
        if (event.target.files.length != 0) {
            // @ts-ignore
            setCategoryImage(URL.createObjectURL(event.target.files[0]))
            //console.log(event.target.files[0])
        }
    }

    const getCroppedImg = () => {
        try {
            const canvas = document.createElement('canvas');
            // @ts-ignore
            const scaleX = image.naturalWidth / image.width;
            // @ts-ignore
            const scaleY = image.naturalHeight / image.height;
            // @ts-ignore
            canvas.width = crop.width;
            // @ts-ignore
            canvas.height = crop.height;
            const ctx = canvas.getContext('2d');
            // @ts-ignore
            ctx.drawImage(
                // @ts-ignore
                image,
                // @ts-ignore
                crop.x * scaleX,
                // @ts-ignore
                crop.y * scaleY,
                // @ts-ignore
                crop.width * scaleX,
                // @ts-ignore
                crop.height * scaleY,
                0,
                0,
                // @ts-ignore
                crop.width,
                // @ts-ignore
                crop.height,
            );
            const base64Image = canvas.toDataURL('image/jpeg', 1);
            const urlDetails = base64Image.split(',');
            // @ts-ignore
            const mime = urlDetails[0].match(/:(.*?);/)[1];
            const bstr = atob(urlDetails[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            // eslint-disable-next-line no-plusplus
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            // @ts-ignore
            setUploadImg(new File([u8arr], uuid(), {type: mime}));
            // @ts-ignore
            setResult(base64Image);
        } catch (e) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Select new image and crop',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }
    return (
        <div>
            <Backdrop open={loading} className={classes.backdrop}>
                <CircularProgress />
            </Backdrop>
            <form className={classes.profile} onSubmit={editCategory} noValidate>
                <TextField
                    error={!!categoryNameError}
                    required
                    fullWidth
                    label="Category Name"
                    autoFocus
                    inputRef={categoryNameRef}
                    onChange={() => {
                        if (categoryNameError !== "") setcategoryNameError("");
                        if (error !== "") setError("");
                    }}
                    helperText={categoryNameError}
                />
                <Typography style={{marginTop: "1rem", color: "#858585"}} variant="subtitle1" gutterBottom>Select
                    Category Image from here</Typography>
                <Container maxWidth={"sm"}>
                    <div>
                        <div className={classes.button}>

                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImage}
                            />
                        </div>
                        <div>
                            {categoryImage && <div>
                                <ReactCrop style={{maxWidth: "100%"}} src={categoryImage}
                                           onImageLoaded={setImage} crop={crop}
                                           onChange={setCrop}/>
                                <br/>
                                <Button className={classes.button} variant="contained"
                                        onClick={getCroppedImg}>crop</Button>
                            </div>}

                            {
                                result && <div>
                                    {/*@ts-ignore*/}
                                    <img src={result} alt='cropped image'/>
                                </div>
                            }
                        </div>
                    </div>
                </Container>
                <div className="btns">
                    <Button
                        style={{marginRight: "5%"}}
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Save Changes
                    </Button>
                    <Button variant="contained" onClick={handleCancel}>
                        Cancel
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default EditCategoryPage;
