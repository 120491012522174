import React, { useEffect, useState } from "react";
import ItemCard from "../ItemCard/itemcard";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CONSTANTS from "../../services/constants";
import history from "../../history";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const axios = require("axios").default;

const useStyles = makeStyles({
  allContainer: {
    border: "2px solid #e6e6e6",
    backgroundColor: "#e6e6e6",
    borderRadius: "5px",
    marginTop: "2rem",
  },
  categoryTitle: {
    textAlign: "left",
    fontWeight: "light",
    emphasis: "medium",
  },
  categoryText: {
    marginLeft: "1.5rem",
    marginRight: "1rem",
  },
  category: {
    marginTop: "1rem",
    marginBottom: "1.5rem",
  },
  viewMore: {
    position: "absolute",
    marginTop: "-2.25rem",
    marginLeft: "68.5rem",
  },
});

export default function CategoryContainer(props) {
  const classes = useStyles();

  const [allProductsOfCategory, setAllProductsOfCategory] = useState([]);

  useEffect(() => {
    const callProduct = async () => {
      const products = await axios.get(
        `${CONSTANTS.HOSTNAME}/api/products/get-products/${props.categoryRow.categoryName}/6`
      );
      setAllProductsOfCategory(products.data.data);
      //console.log(products.data.data);
    };
    callProduct();
  }, []);

  useEffect(() => {
    // console.log(allProductsOfCategory);
  }, [allProductsOfCategory]);

  const handleCategory = (name) => (event) => {
    history.push(`/category/${name}`);
  };

  return allProductsOfCategory.length !== 0 ? (
    <Grid container item xs={12} className={classes.allContainer}>
      <Grid item xs={12}>
        <div className={classes.category}>
          <Typography
            className={classes.categoryText}
            variant="overline"
            display="inline"
            gutterBottom
          >
            Category
          </Typography>
          <Typography
            className={classes.categoryTitle}
            display="inline"
            gutterBottom
            variant="h5"
          >
            {props.categoryRow.categoryName}
          </Typography>
          <div className={classes.viewMore}>
            <Button
              onClick={handleCategory(props.categoryRow.categoryName)}
              color="primary"
            >
              VIEW MORE&nbsp;
              <NavigateNextIcon />
            </Button>
          </div>
        </div>
      </Grid>
      {allProductsOfCategory.map((product, i) => (
        <Grid item xs={2}>
          <ItemCard key={i} product={product} />
        </Grid>
      ))}
    </Grid>
  ) : (
    <></>
  );
}
