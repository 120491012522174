import React, { useMemo, useState } from "react";
import { Router } from "react-router-dom";
import history from "./history";
import UserContext from "./helpers/UserContext";

import Routes from "./routes/routes";

export default function App() {
  const [context, setContext] = useState(null);
  const providerValue = useMemo(
    () => ({ context, setContext }),
    [context, setContext]
  );

  return (
    <Router history={history}>
      <UserContext.Provider value={providerValue}>
        <Routes />
      </UserContext.Provider>
    </Router>
  );
}
