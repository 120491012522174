import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import {Grid, Paper} from '@material-ui/core';
import axios from 'axios';
import CONSTANTS from '../../services/constants';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary">
            {'Copyright © '}{' '}
            {new Date().getFullYear()}{' '}
            All Rights Reserved by{' '}
            <Link color="inherit" href="https://hexception.live/">
                hException
            </Link>
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '65vh',
    },
    main: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    footer: {

        padding: theme.spacing(5, 5),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
    title: {
        padding: theme.spacing(2),
        textAlign: 'left',
        fontSize: "17px",
        paddingLeft:"35px",
        color: theme.palette.grey[600],
        boxShadow: "none",
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
    list: {
        listStyleType: "none",
        textAlign:"left"
    },
    iconList: {
        listStyleType: "none",
        float: "right",
        fontSize:"24px",
        marginTop: "-25px"
    },
    display: {
        display: "inline",
        marginLeft:"40px",

    },
    aboutText: {
        marginLeft: "4%"
    }
}));

// Shuffle Array elements randomly
function shuffle(array: any[]) {
    var currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }


const StickyFooter = () => {

    const [categories, setCategories] = useState<any[]>([]);
    const classes = useStyles();

    useEffect(() => {
        const getCategories = async () => {
          const products = await axios.get(
            `${CONSTANTS.HOSTNAME}/api/categories/get-all-categories`
          );
          await shuffle(products.data.data);
          setCategories(products.data.data.slice(0, 5));
        };
        getCategories();
      }, []);

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <footer className={classes.footer}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.title}>About</Paper>
                        <p className={classes.aboutText}>This is an online cart for Natural Products of Kudamalana Organic Estate 
                        and Value Additions of Natural Agriculture Products in and around the Estate run as Social Inclusion 
                        Innovations.</p>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Paper className={classes.title}>Categories</Paper>
                        <ul className={classes.list}>
                            {
                                categories?
                                    categories.map((item, i) => (
                                        <li><Link color="inherit" href={`/category/${item.categoryName}`}>{item.categoryName}</Link></li>
                                    ))
                                    : null
                            }
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Paper className={classes.title}>Quick Links</Paper>
                        <ul className={classes.list}>
                            <li><Link color="inherit" href="/">Home</Link></li>
                            <li><Link color="inherit" href="/account">My Account</Link></li>  
                            <li><Link color="inherit" href="/about">About Us</Link></li>
                            <li><Link color="inherit" href="/contact">Contact Us</Link></li>  
                        </ul>
                    </Grid>
                </Grid>
                <hr/>
                <Copyright/>
                <ul className={classes.iconList}>
                    <li className={classes.display}><a href=""><i className="fab fa-facebook-square"></i></a></li>
                    <li className={classes.display}><a href=""><i className="fab fa-linkedin"></i></a></li>
                    <li className={classes.display}><a href=""><i className="fab fa-medium"></i></a></li>
                </ul>
            </footer>
        </div>
    );
}

export default StickyFooter; 
