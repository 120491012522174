import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Shipped from './Shipped';
import AllOrders from './AllOrders';
import UnShipped from './UnShipped';
import SettingsIcon from '@material-ui/icons/Settings';
import history from "../../history";
import { Container, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={4}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#e6e6e6",
        margin: "3rem",
        padding: "2rem",
        borderRadius: "5px",
    },
    settingsButton: {
        borderRadius: "50px",
        backgroundColor: "#214003",
        '&:hover': {
            backgroundColor: "#b6ff6f",
        },
        padding: "1rem",
        marginRight: "8.5rem",
        marginTop: "1.5rem",
        marginBottom: "1.5rem",
        float: "right",
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    container: {
        marginTop: "2.75%",
        marginBottom: "2.75%",
    },
}));

export default function Profile() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const handleOpenSettingsPage = () => {
        history.push('/settings');
    }

    return (
        <div>

            <Container maxWidth={"lg"} className={classes.container}>
                <Button color="primary" variant="contained" className={classes.settingsButton} onClick={handleOpenSettingsPage}>
                    <SettingsIcon className={classes.extendedIcon} />
                    Profile Settings
                </Button>
                <div className={classes.root}>
                    <AppBar position="static" style={{backgroundColor: "#FAFAFA"}}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="All Orders" {...a11yProps(0)} />
                            <Tab label="Unshipped" {...a11yProps(1)} />
                            <Tab label="Shipped" {...a11yProps(2)} />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <AllOrders />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <UnShipped />
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction}>
                            <Shipped />
                        </TabPanel>
                    </SwipeableViews>
                </div>
            </Container>
        </div>
    );
}
