import React, { useEffect, useRef, useState } from "react";
import history from "../../history";
import { Auth } from "aws-amplify";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Container,
  Backdrop,
  CircularProgress,
  TextField,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  CssBaseline,
  Typography,
  Collapse,
  Link,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import CONSTANTS from "../../services/constants";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = (props: any) => {
  const classes = useStyles();

  const userNameRef = useRef<HTMLInputElement>();
  const passwordRef = useRef<HTMLInputElement>();

  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState("");
  const [toUrl, setToUrl] = useState("/");

  useEffect(() => {
    if (props.location.state && props.location.state.from)
      setFrom(props.location.state.from);
    if (props.location.state && props.location.state.toUrl)
      setToUrl(props.location.state.toUrl);
  }, []);

  const signIn = async (event: any) => {
    event.preventDefault();
    let userName = "",
      password = "";
    if (userNameRef.current) userName = userNameRef.current.value;
    if (passwordRef.current) password = passwordRef.current.value;
    const emailPattern = /(.+)@(.+){2,}\.(.+){2,}/;
    if (userName === "" && password === "") {
      setUserNameError("Cannot be empty");
      setPasswordError("Cannot be empty");
    } else if (userName === "") setUserNameError("Cannot be empty");
    else if (password === "") setPasswordError("Cannot be empty");
    else if (!emailPattern.test(userName))
      setUserNameError("Email is not valid");
    else {
      setLoading(true);
      try {
        const user = await Auth.signIn(userName, password);
        // console.log(user);
        const res = await axios.post(
          `${CONSTANTS.HOSTNAME}/api/users/log-user`,
          { email: userName }
        );
        // console.log(res.data.data);
        localStorage.setItem("userID", res.data.data.id);
        localStorage.setItem("ROLE", res.data.data.role);
        history.push(toUrl);
      } catch (e) {
        setError(e.message);
        if (e.name === "UserNotConfirmedException")
          history.push({
            pathname: "/verify-account",
            state: {
              email: userName,
              from: "login",
            },
          });
        console.log(e);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {from && (
            <Alert severity="info">
              Password changed successfully. You can log back in now.
            </Alert>
          )}
          <form className={classes.form} onSubmit={signIn} noValidate>
            <TextField
              error={!!userNameError}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email Address"
              autoComplete="email"
              autoFocus
              inputRef={userNameRef}
              onChange={() => {
                if (userNameError !== "") setUserNameError("");
                if (error !== "") setError("");
              }}
              helperText={userNameError}
            />
            <TextField
              error={!!passwordError}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              autoComplete="current-password"
              inputRef={passwordRef}
              onChange={() => {
                if (error !== "") setError("");
                if (passwordError !== "") setPasswordError("");
              }}
              helperText={passwordError}
            />
            <Collapse in={!!error}>
              <Alert severity="error">{error}</Alert>
            </Collapse>

            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={signIn}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
};

export default Login;
