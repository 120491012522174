import React, { useEffect, useState } from "react";
import CartItem from "../CartItem/CartItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Backdrop, CircularProgress, Container, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import history from "../../history";
import { useLocation } from "react-router-dom";
import CONSTANTS from "../../services/constants";
import axios from "axios";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    total: {
      flexGrow: 1,
      marginRight: 10,
    },
    cart: {
      flexGrow: 1,
      marginLeft: 20,
      marginBottom: 10,
    },
    checkbox: {
      marginLeft: 5,
    },
    cardPadding: {
      paddingTop: "40px",
    },
    media: {
      height: 140,
    },
    orderTotal: {
      textAlign: "right",
      paddingRight: "5%",
    },
    buyNow: {
      marginLeft: "auto",
      marginRight: "auto",
      padding: "3%",
      margin: "5%",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

const CartPage = (props: any) => {
  const [cartItems, setCartItems] = useState([]);
  const [selectedCartItems, setSelectedCartItems] = useState([]);
  const [state, setState] = useState({
    totalCost: 0,
    items: 0,
  });
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);

  const handleCheckout = async () => {
    const res = await axios.get(
      // @ts-ignore
      `${CONSTANTS.HOSTNAME}/api/cart/get-all-cart-items/${props.location.state.id}`
    );
    // @ts-ignore
    setCartItems(res.data.data.result);
    console.log(res.data.data.result);
    history.push({
      pathname: "/checkout",
      state: { orderDetails: res.data.data.result },
    });
  };

  const handleSelect = (id: string, flag: boolean, prevTotal: number) => {
    if (flag) {
      setSelectedCartItems([
        // @ts-ignore
        ...selectedCartItems,
        // @ts-ignore
        cartItems.find((item: any) => item.itemId === id),
      ]);
      const value = Number(state.totalCost) + Number(prevTotal);
      setState({
        ...state,
        totalCost: value,
      });
    } else {
      setSelectedCartItems(
        // @ts-ignore
        selectedCartItems.filter((item) => item.itemId !== id)
      );
      const value = Number(state.totalCost) - Number(prevTotal);
      setState({
        ...state,
        totalCost: value,
      });
    }
  };

  const handleTotalCost = (flag: boolean, newValue: number) => {
    const value = Number(state.totalCost) + Number(newValue);
    if (flag) {
      setState({
        ...state,
        totalCost: value,
      });
    }
  };

  const handleDelete = async (id: string, prevTotal: number) => {
    setLoading(true);
    try {
      await axios.delete(
        `${CONSTANTS.HOSTNAME}/api/cart/delete-cart-item/${id}`
      );
      window.location.reload();
      // @ts-ignore
      setCartItems(cartItems.filter((item: any) => item.itemId !== id));
      const value = Number(state.totalCost) - Number(prevTotal);
      setState({
        ...state,
        totalCost: value,
      });
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllCartItems();
  }, []);

  const getAllCartItems = async () => {
    setLoading(true);
    try {
      // @ts-ignore
      const res = await axios.get(
        `${CONSTANTS.HOSTNAME}/api/cart/get-all-cart-items/${props.location.state.id}`
      );
      setState({
        ...state,
        totalCost: res.data.data.totalCost[0].totalCost,
        items: res.data.data.result.length,
      });
      console.log(res.data.data.result);
      setCartItems(res.data.data.result);
      setSelectedCartItems(res.data.data.result);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const classes = useStyles();
  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <Container maxWidth={"lg"}>
        <Grid className={classes.cardPadding} container spacing={1}>
          <Grid item xs={8}>
            <Card className={classes.cart}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {/*// @ts-ignore*/}
                  Cart Items ({selectedCartItems.length})
                </Typography>
              </CardContent>
              <CardActions>
                {/*<FormControlLabel className={classes.checkbox}*/}
                {/*                  control={<Checkbox checked={checked} onChange={handleChange}*/}
                {/*                                     name="select"/>}*/}
                {/*                  label="Select All"*/}
                {/*/>*/}
              </CardActions>
            </Card>
            {cartItems.map((item, i) => (
              <CartItem
                key={i}
                id={item["itemId"]}
                productId={item["productId"]}
                name={item["productName"]}
                description={item["productDescription"]}
                unitPrice={item["unitPrice"]}
                quantity={item["quantity"]}
                productQuantity={item["productQuantity"]}
                total={item["totalCost"]}
                coverImage={item["coverImage"]}
                checkAll={checked}
                handleSelect={handleSelect}
                handleDelete={handleDelete}
                handleTotalCost={handleTotalCost}
              />
            ))}
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.total}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Order Summary
                  </Typography>
                  <Grid className={classes.cardPadding} container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        component="p"
                      >
                        Sub Total
                      </Typography>
                      {/*<Typography variant="body1" color="textSecondary" component="p">*/}
                      {/*    Shipping*/}
                      {/*</Typography>*/}
                      <Typography variant="h5" component="h5">
                        Total
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        className={classes.orderTotal}
                        variant="body1"
                        color="textSecondary"
                        component="p"
                      >
                        LKR {state.totalCost}
                      </Typography>
                      {/*<Typography className={classes.orderTotal} variant="body1"*/}
                      {/*            color="textSecondary"*/}
                      {/*            component="p">*/}
                      {/*    LKR 0.00*/}
                      {/*</Typography>*/}
                      <Typography
                        className={classes.orderTotal}
                        variant="h5"
                        component="h5"
                      >
                        LKR {state.totalCost}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  disabled={state.totalCost == 0 || state.items == 0}
                  className={classes.buyNow}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  //   className={classes.submit}
                  onClick={handleCheckout}
                >
                  {/*//@ts-ignore*/}
                  Checkout ({selectedCartItems.length})
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CartPage;
