import React, { useEffect, useRef, useState } from "react";
import history from "../../history";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Container,
  Backdrop,
  CircularProgress,
  TextField,
  Button,
  CssBaseline,
  Typography,
  Collapse,
  Box,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import { Alert } from "@material-ui/lab";
import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const VerificationTab = (props: any) => {
  const classes = useStyles();
  const state = props.location.state || { from: "" };

  const codeRef = useRef<HTMLInputElement>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    codeError: false,
    resError: false,
    message: "",
    resMessage: "",
  });

  const resendCode = async () => {
    setLoading(true);
    try {
      const res = await Auth.resendSignUp(state.email);
      setError({
        codeError: false,
        resError: false,
        message: "",
        resMessage: "",
      });
      console.log(res);
    } catch (error) {
      console.log(error);
      setError({
        codeError: false,
        resError: true,
        resMessage: error.message,
        message: "",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (state === undefined) history.push("/login");
    else if (state.from === "login")
      resendCode().then(() => {
        console.log("New code fetched!");
      });
  }, []);

  const verifyAccount = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    let verificationCode = "";
    if (codeRef.current) verificationCode = codeRef.current.value;
    if (!/^\d{6}$/.test(verificationCode))
      setError({
        codeError: true,
        resError: false,
        resMessage: "",
        message: "Something appears to be wrong with the code",
      });
    else {
      try {
        const res = await Auth.confirmSignUp(state.email, verificationCode);
        // console.log(res);
        if (res === "SUCCESS") {
          // console.log(`${state.email} logged in successfully!`);
          history.push("/login");
        } else {
          console.log(res);
        }
      } catch (error) {
        console.log(error);
        setError({
          codeError: false,
          resError: true,
          resMessage: error.message,
          message: "",
        });
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            {state.from !== "login" ? (
              <ArrowForwardIosIcon />
            ) : (
              <EnhancedEncryptionIcon />
            )}
          </Avatar>
          <Typography component="h1" variant="h5">
            One last step...
          </Typography>
          <Box mt={2}>
            {state.from === "login" ? (
              <Alert severity="warning">
                We've sent you an email. You need to verify your account before
                you login!
              </Alert>
            ) : (
              <Alert severity="info">
                Check you're email we've sent you an email with a code.
              </Alert>
            )}
          </Box>
          <form className={classes.form} noValidate onSubmit={verifyAccount}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Email Address"
              name="email"
              InputProps={{
                readOnly: true,
              }}
              defaultValue={state.email}
            />
            <TextField
              error={error.codeError}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Verification code"
              type="tel"
              onChange={() =>
                setError({
                  codeError: false,
                  resError: false,
                  resMessage: "",
                  message: "",
                })
              }
              inputRef={codeRef}
              helperText={error.message}
            />
            <Box mb={1}>
              <Collapse in={error.resError}>
                <Alert severity="error">{error.resMessage}</Alert>
              </Collapse>
            </Box>
            <Button color="primary" size="small" onClick={resendCode}>
              Didn't receive code? Resend
            </Button>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Verify and sign-in
            </Button>
          </form>
        </div>
      </Container>
    </>
  );
};

export default VerificationTab;
