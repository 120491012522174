import React, { Component, useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import MainMenu from "../components/MainMenu/mainmenu";
import history from "../history";
import StickyFooter from "../components/Footer/footer";
import { Auth } from "aws-amplify";
import UserContext from "../helpers/UserContext";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PrivateRoute = ({ component: Component, ...rest }) => {
  const classes = useStyles();

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setContext } = useContext(UserContext);

  const authenticate = async () => {
    setLoading(true);
    try {
      const user = (await Auth.currentAuthenticatedUser()).attributes;
      setContext(user);
      setAuthenticated(true);
      setLoading(false);
      return true;
    } catch (error) {
      setAuthenticated(false);
      console.log(error);
      setLoading(false);
      return false;
    }
  };

  useEffect(() => {
    // console.log(validateAuthOnAction);
    validateAuth().then(() => console.log("Authenticated!"));
  }, []);

  const validateAuth = async () => {
    if (!(await authenticate())) {
      await Swal.fire({
        allowOutsideClick: false,
        title: "Restricted!",
        text: "You need to be logged in",
        showCancelButton: false,
        confirmButtonColor: "#214003",
        confirmButtonText: "Login",
      });
      history.push({
        pathname: "/login",
        state: {
          toUrl: rest.location.pathname,
        },
      });
      return false;
    }
  };

  const restrictAndPushHome = async () => {
    let timerInterval;
    await Swal.fire({
      title: "Whoops!",
      allowOutsideClick: false,
      html: "Redirecting you back to home...",
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });
    history.push("/");
  };

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <MainMenu isAuthenticated={authenticated} />
      {authenticated && (
        <Route
          {...rest}
          render={(routeProps) => (
            <Component
              {...routeProps}
              {...rest}
              restrictAndPushHome={restrictAndPushHome}
            />
          )}
        />
      )}
      {!authenticated && (
        <div
          style={{
            minHeight: "400px",
            backgroundColor: "rgba(255, 0, 0, 0.1)",
            margin: "190px",
            borderRadius: "5px",
          }}
        />
      )}
      <StickyFooter />
    </>
  );
};

export default PrivateRoute;
