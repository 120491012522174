import React, { useEffect, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Backdrop,
  CircularProgress,
  Collapse,
  Container,
  Grid,
  TextField,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import axios from "axios";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import CONSTANTS from "../../services/constants";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    padding: "2rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  editButton: {
    borderRadius: "50px",
    backgroundColor: "#214003",
    "&:hover": {
      backgroundColor: "#b6ff6f",
    },
    padding: "1rem",
    marginRight: "1.5rem",
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    float: "right",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  container: {
    marginBottom: "2.75%",
  },
  paper: {
    marginTop: "2rem",
    width: "80%",
    marginLeft: "10%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

export default function Profile() {
  const firstNameRef = useRef<HTMLInputElement>();
  const lastNameRef = useRef<HTMLInputElement>();
  const addressRef = useRef<HTMLInputElement>();
  const emailAddressRef = useRef<HTMLInputElement>();
  const firstNameEditRef = useRef<HTMLInputElement>();
  const lastNameEditRef = useRef<HTMLInputElement>();
  const addressEditRef = useRef<HTMLInputElement>();

  const [firstNameEditError, setFirstNameEditError] = useState("");
  const [lastNameEditError, setLastNameEditError] = useState("");
  const [addressEditError, setAddressEditError] = useState("");

  const classes = useStyles();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [editingUser, seteditingUser] = useState(false);

  useEffect(() => {
    fetchUserDetails();
  }, [editingUser]);

  const fetchUserDetails = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${CONSTANTS.HOSTNAME}/api/users/get-user/${localStorage.getItem(
          "userID"
        )}`
      );

      // @ts-ignore
      firstNameRef.current.value = res.data.data[0].firstName;
      // @ts-ignore
      lastNameRef.current.value = res.data.data[0].lastName;
      // @ts-ignore
      addressRef.current.value = res.data.data[0].address;
      // @ts-ignore
      emailAddressRef.current.value = res.data.data[0].email;
      // @ts-ignore
      firstNameEditRef.current.value = res.data.data[0].firstName;
      // @ts-ignore
      lastNameEditRef.current.value = res.data.data[0].lastName;
      // @ts-ignore
      addressEditRef.current.value = res.data.data[0].address;
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const editUser = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    let firstName = "",
      lastName = "",
      address = "";

    if (firstNameEditRef.current) firstName = firstNameEditRef.current.value;
    if (lastNameEditRef.current) lastName = lastNameEditRef.current.value;
    if (addressEditRef.current) address = addressEditRef.current.value;

    const userDetails = {
      firstName: firstName,
      lastName: lastName,
      address: address,
    };
    //console.log("Edited User Details");
    //console.log(userDetails);

    if (firstName === "") setFirstNameEditError("First Name Cannot be empty.");
    else if (lastName === "")
      setLastNameEditError("Last Name Cannot be empty.");
    else if (address === "") setAddressEditError("Address Cannot be empty.");
    else {
      try {
        const res = await axios.put(
          `${CONSTANTS.HOSTNAME}/api/users/update-user/${localStorage.getItem(
            "userID"
          )}`,
          userDetails
        );
        //console.log(res.data);
        seteditingUser(!editingUser);
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Profile edit successfull",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (e) {
        await Swal.fire({
          position: "center",
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
    setLoading(false);
  };

  return (
    <div className={classes.root}>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <Grid container direction={"column"} alignItems="flex-end">
        <Button
          color={editingUser ? "secondary" : "primary"}
          variant="contained"
          className={classes.editButton}
          onClick={() => seteditingUser(!editingUser)}
        >
          {editingUser ? (
            <ViewIcon className={classes.extendedIcon} />
          ) : (
            <EditIcon className={classes.extendedIcon} />
          )}
          {editingUser ? "View Profile" : "Edit Profile"}
        </Button>
      </Grid>
      <Container maxWidth={"lg"} className={classes.container}>
        <Collapse in={!editingUser}>
          <>
            <Typography variant={"h5"}>My Profile</Typography>
            <form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    inputRef={firstNameRef}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    autoFocus
                    inputRef={lastNameRef}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Address"
                    id="adress"
                    autoFocus
                    inputRef={addressRef}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Email Address"
                    autoFocus
                    inputRef={emailAddressRef}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </form>
          </>
        </Collapse>
        <Collapse in={editingUser}>
          <Typography variant={"h5"}>Edit Profile</Typography>
          <form className={classes.form} onSubmit={editUser} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!firstNameEditError}
                  required
                  variant="outlined"
                  fullWidth
                  id="firstNameEdit"
                  label="First Name"
                  InputLabelProps={{ shrink: true }}
                  inputRef={firstNameEditRef}
                  onChange={() => {
                    if (firstNameEditError !== "") setFirstNameEditError("");
                    if (error !== "") setError("");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!lastNameEditError}
                  required
                  variant="outlined"
                  fullWidth
                  id="lastNameEdit"
                  label="Last Name"
                  InputLabelProps={{ shrink: true }}
                  inputRef={lastNameEditRef}
                  onChange={() => {
                    if (lastNameEditError !== "") setLastNameEditError("");
                    if (error !== "") setError("");
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!addressEditError}
                  required
                  variant="outlined"
                  fullWidth
                  label="Address"
                  id="adressEdit"
                  inputRef={addressEditRef}
                  InputLabelProps={{ shrink: true }}
                  onChange={() => {
                    if (addressEditError !== "") setAddressEditError("");
                    if (error !== "") setError("");
                  }}
                />
              </Grid>
            </Grid>
            <Button
              style={{ marginTop: "3%" }}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save Changes
            </Button>
          </form>
        </Collapse>
      </Container>
    </div>
  );
}
