import React, { useEffect, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Collapse,
  Container,
  Grid,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import axios from "axios";
import CONSTANTS from "../../../services/constants";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import history from "../../../history";
// @ts-ignore
import { withSwalInstance } from "sweetalert2-react";
import Swal from "sweetalert2";
import { Storage } from "aws-amplify";

// @ts-ignore
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

// @ts-ignore
import { v4 as uuid } from "uuid";
import CancelIcon from "@material-ui/icons/Cancel";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const columns = [
  {
    id: "categoryName",
    label: "Category Name",
    minWidth: 100,
    align: "left",
  },
  {
    id: "edit",
    label: "Actions",
    minWidth: 100,
    align: "left",
    format: (value: any) => value.toLocaleString("en-US"),
  },
];

const SweetAlert = withSwalInstance(Swal);

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  profile: {
    marginLeft: "auto",
    marginRight: "auto",
    margin: theme.spacing(1),
    width: "30ch",
    display: "block",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  container: {
    maxHeight: 440,
    tableLayout: "fixed",
  },
  paper: {
    marginTop: "2rem",
    width: "80%",
    marginLeft: "10%",
  },
  margin: {
    borderRadius: "50px",
    padding: "1%",
    marginRight: "11.5%",
    marginTop: "1%",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  form: {
    padding: "2rem",
    marginLeft: "25%",
    width: "50%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    border: "2px solid #e6e6e6",
    borderRadius: "5px",
    backgroundColor: "#e6e6e6",
  },
  button: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ManageCategories = (props: any) => {
  const classes = useStyles();
  const categoryNameRef = useRef<HTMLInputElement>();

  const [categoryNameError, setCategoryNameError] = useState("");
  const [error, setError] = useState("");
  const [addingCategory, setAddingCategory] = useState(false);
  const [state, setState] = useState({
    categories: [],
    show: false,
  });
  const [categoryImage, setCategoryImage] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1 / 1 });
  const [result, setResult] = useState(null);
  const [uploadImg, setUploadImg] = useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [loading, setLoading] = useState(false);

  const handleChangePage = async (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchCategories().then((res) => {
      const categories = res.data.data;
      setState({
        ...state,
        categories: categories,
      });
      console.log(categories);
    });
  }, []);

  const fetchCategories = async () => {
    //const res = await Storage.get("SSSS3109f171-0eab-4e60-bb46-60e838346f6a");
    // @ts-ignore
    //setCategoryImage(res);
    return await axios.get(
      `${CONSTANTS.HOSTNAME}/api/categories/get-all-categories`
    );
  };

  const addCategory = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    let categoryName = "";
    if (categoryNameRef.current) categoryName = categoryNameRef.current.value;
    if (categoryName === "") setCategoryNameError("Cannot be empty");
    else {
      try {
        const res = await Storage.put(categoryName + uuid(), uploadImg);
        const categoryDetails = {
          categoryName: categoryName,
          // @ts-ignore
          categoryImage: res.key,
        };
        //console.log(res.key);
        console.log(categoryDetails);
        const details = await axios.post(
          `${CONSTANTS.HOSTNAME}/api/categories/create-category`,
          categoryDetails
        );
        // console.log(res);
        setState({
          ...state,
          // @ts-ignore
          categories: state.categories.concat(details.data.data),
        });
        await Toast.fire({
          icon: "success",
          title: "Category Added Successfully",
        });
        history.push({
          pathname: `/adminpanel/manage-categories/view-category`,
          //@ts-ignore
          state: { id: details.data.data.categoryId },
        });
        if (categoryNameRef.current) categoryNameRef.current.value = "";
      } catch (e) {
        await Toast.fire({
          icon: "error",
          title: "Something went wrong",
        });
      }
    }
    setLoading(false);
  };

  const handleChange = async (event: any, param: any, key: any) => {
    event.preventDefault();
    console.log("You clicked edit.");
    switch (key) {
      case "edit":
        console.log(param);
        history.push({
          pathname: `/adminpanel/manage-categories/edit-category`,
          state: { id: param },
        });
        break;
      case "view":
        console.log(param);
        history.push({
          pathname: `/adminpanel/manage-categories/view-category`,
          state: { id: param },
        });
        break;
      case "delete":
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            try {
              const res = axios
                .delete(
                  `${CONSTANTS.HOSTNAME}/api/categories/delete-category/${param}`
                )
                .then((res) => {
                  console.log(res);
                  setState({
                    ...state,
                    // @ts-ignore
                    categories: state.categories.filter(
                      // @ts-ignore
                      (category) => category.categoryId !== param
                    ),
                  });
                  Toast.fire({
                    icon: "success",
                    title: "Category Deleted Successfully",
                  });
                })
                .catch((err) => {
                  Swal.fire(
                    "Cannot Delete Category.",
                    `${err.response.data.error}`,
                    "info"
                  );
                });
            } catch (e) {
              Swal.fire("Error", `${e.response.data.error}`, "error");
              console.log("Error");
              console.log(e.error);
              setError(e.message);
            }
          }
        });
        break;
      default:
        break;
    }
  };

  const handleImage = async (event: any) => {
    if (event.target.files.length != 0) {
      // @ts-ignore
      setCategoryImage(URL.createObjectURL(event.target.files[0]));
      console.log(event.target.files[0]);
    }
  };

  const getCroppedImg = () => {
    try {
      const canvas = document.createElement("canvas");
      // @ts-ignore
      const scaleX = image.naturalWidth / image.width;
      // @ts-ignore
      const scaleY = image.naturalHeight / image.height;
      // @ts-ignore
      canvas.width = crop.width;
      // @ts-ignore
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      // @ts-ignore
      ctx.drawImage(
        // @ts-ignore
        image,
        // @ts-ignore
        crop.x * scaleX,
        // @ts-ignore
        crop.y * scaleY,
        // @ts-ignore
        crop.width * scaleX,
        // @ts-ignore
        crop.height * scaleY,
        0,
        0,
        // @ts-ignore
        crop.width,
        // @ts-ignore
        crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg", 1);
      const urlDetails = base64Image.split(",");
      // @ts-ignore
      const mime = urlDetails[0].match(/:(.*?);/)[1];
      const bstr = atob(urlDetails[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      // eslint-disable-next-line no-plusplus
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      // @ts-ignore
      setUploadImg(new File([u8arr], uuid(), { type: mime }));
      // @ts-ignore
      setResult(base64Image);
    } catch (e) {
      console.log("crop the image");
    }
  };

  return (
    <div className={classes.root}>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <SweetAlert
        show={state.show}
        title="Demo"
        text="SweetAlert in React"
        onConfirm={() => setState({ ...state, show: false })}
      />
      <Grid container direction={"column"} alignItems="flex-end">
        <Button
          color={addingCategory ? "secondary" : "primary"}
          variant="contained"
          disabled={props.role}
          className={classes.margin}
          onClick={() => setAddingCategory(!addingCategory)}
        >
          {addingCategory ? (
            <CancelIcon className={classes.extendedIcon} />
          ) : (
            <AddCircleIcon className={classes.extendedIcon} />
          )}
          {addingCategory ? "Cancel" : "New Category"}
        </Button>
      </Grid>
      <Container maxWidth={"lg"}>
        <Collapse in={addingCategory}>
          <>
            <form className={classes.form} noValidate onSubmit={addCategory}>
              <TextField
                error={!!categoryNameError}
                required
                fullWidth
                label="Category Name"
                autoFocus
                inputRef={categoryNameRef}
                onChange={() => {
                  if (categoryNameError !== "") setCategoryNameError("");
                  if (error !== "") setError("");
                }}
                helperText={categoryNameError}
              />
              <Typography
                style={{ marginTop: "1rem", color: "#858585" }}
                variant="subtitle1"
                gutterBottom
              >
                Select Category Image from here
              </Typography>
              <Container maxWidth={"sm"}>
                <div>
                  <div className={classes.button}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImage}
                    />
                  </div>
                  <div>
                    {categoryImage && (
                      <div>
                        <ReactCrop
                          style={{ maxWidth: "100%" }}
                          src={categoryImage}
                          onImageLoaded={setImage}
                          crop={crop}
                          onChange={setCrop}
                        />
                        <Button
                          className={classes.button}
                          variant="contained"
                          onClick={getCroppedImg}
                        >
                          crop
                        </Button>
                      </div>
                    )}
                    {result && (
                      <div>
                        {/*@ts-ignore*/}
                        <img src={result} alt="cropped image" />
                      </div>
                    )}
                  </div>
                </div>
              </Container>
              <br />
              <div className="btns">
                <Button
                  style={{ marginRight: "5%" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save Changes
                </Button>
              </div>
            </form>
          </>
        </Collapse>
        <Collapse in={!addingCategory}>
          <Paper className={classes.paper}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="center"
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.categories
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align="center">
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                                {column.id == "edit" && (
                                  <>
                                    <Button
                                      color="default"
                                      onClick={(e) => {
                                        handleChange(
                                          e,
                                          row["categoryId"],
                                          "view"
                                        );
                                      }}
                                    >
                                      VIEW
                                    </Button>
                                    <Button
                                      color="primary"
                                      disabled={props.role}
                                      onClick={(e) => {
                                        handleChange(
                                          e,
                                          row["categoryId"],
                                          "edit"
                                        );
                                      }}
                                    >
                                      EDIT
                                    </Button>
                                    <Button
                                      style={{ color: "red" }}
                                      disabled={props.role}
                                      color="secondary"
                                      onClick={(e) => {
                                        handleChange(
                                          e,
                                          row["categoryId"],
                                          "delete"
                                        );
                                      }}
                                    >
                                      DELETE
                                    </Button>
                                  </>
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={state.categories.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Collapse>
      </Container>
    </div>
  );
};

export default ManageCategories;
