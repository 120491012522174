import React, {useEffect, useState} from 'react';
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import {Grid, Fab, Button, Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PulaPic from "../../../../Assets/Images/Pulsara.jpg";
import axios from "axios";
import CONSTANTS from "../../../../services/constants";
import {useLocation} from "react-router-dom";
import {Storage} from "aws-amplify";


const useStyles = makeStyles((theme)=>({
    root: {
        flexGrow: 1,
    },
    cardPadding: {
        border: "2px solid #e6e6e6",
        backgroundColor:"#e6e6e6",
        borderRadius:"5px",
        marginTop: "3rem",
    },
    typeHeading:
        {
            textAlign: "left",
            // fontWeight:"light",
            emphasis:"medium"
        },
    categoryText:
        {
            marginLeft: "1.5rem",
            marginRight:"1rem",

        },
    category:{
        display:"block",
        marginLeft: "1.5rem",
        marginTop: "2rem",
        marginBottom:"1rem"
    },
    media: {
        width: "50%",    // 16:9,
        borderRadius: "5px",
        cursor: "pointer",
        maxWidth: "200px",
        maxHeight: "200px",
        marginLeft: "10rem",

    },
}));

const ViewCategoryPage = () => {
    const classes = useStyles();
    const [state, setState] = useState({
        categoryId: "",
        categoryName :"",
        categoryImage:"",
    })

    const location = useLocation();
    useEffect(() => {
        // @ts-ignore
        //console.log(location.state.id);
    }, [location]);

    useEffect(() => {
        const callCategory = async () => {
            try {
                // @ts-ignore
                const category = await axios.get(`${CONSTANTS.HOSTNAME}/api/categories/get-category/${location.state.id}`);

                const imgURL = await Storage.get(category.data.data.categoryImage);

                console.log(category.data.data);
                setState({
                    ...state,
                    categoryId: category.data.data.categoryId,
                    categoryName :category.data.data.categoryName,
                    // @ts-ignore
                    categoryImage: imgURL
                })
            } catch (e) {
                console.log(e);
            }
        }
        callCategory();

    }, []);
    return (
        <>
            <Container maxWidth={"sm"}>
                <Grid className={classes.cardPadding} container spacing={5}>
                    <Grid item xs={12}>
                        <div className={classes.category}>
                            <Typography className={classes.categoryText} variant="overline" display="inline" gutterBottom>
                                Category
                            </Typography>
                            {/*// @ts-ignore*/}
                            <Typography className={classes.typeHeading}  display="inline" gutterBottom variant="h5"  >{state.categoryName}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <img className={classes.media} src={state.categoryImage} alt={state.categoryName}/>
                    </Grid>
                </Grid>
            </Container>
        </>
    );

};

export default ViewCategoryPage;