import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import PaymentModal from "./PaymentModal/PaymentModal";

const products = [
    {name: 'Product 1', desc: 'A nice thing', price: '$9.99'},
    {name: 'Product 2', desc: 'Another thing', price: '$3.45'},
    {name: 'Product 3', desc: 'Something else', price: '$6.51'},
    {name: 'Product 4', desc: 'Best thing of all', price: '$14.11'},
    {name: 'Shipping', desc: '', price: 'Free'},
];
const addresses = ['1 Material-UI Drive', 'Reactville', 'Anytown', '99999', 'USA'];
const payments = [
    {name: 'Card type', detail: 'Visa'},
    {name: 'Card holder', detail: 'Mr John Smith'},
    {name: 'Card number', detail: 'xxxx-xxxx-xxxx-1234'},
    {name: 'Expiry date', detail: '04/2024'},
];

const useStyles = makeStyles((theme) => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: 700,
    },
    title: {
        marginTop: theme.spacing(2),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));


export default function Review(props: any) {
    const classes = useStyles();
    const [paying, setPaying] = useState(false);
    const [orderDetails, setOrderDetails] = useState(props.orderDetails);
    const [shippingDetails, setShippingDetails] = useState(props.shippingDetails);
    const [totalCost, setTotalCost] = useState(0);
    const handleBack = () => {
        props.handleBack();
    }

    const donePayment = () => {
        setPaying(false);
    }
    useEffect(() => {
        calculateTotal();
    }, []);

    const calculateTotal = async () => {
        try{
        if (orderDetails.length == 1 && orderDetails[0].selectedQuantity !=null ) {
            const total = parseInt(orderDetails[0].productPrice) * parseInt(orderDetails[0].selectedQuantity)
            setTotalCost(total);

        } else {
            let total = 0;
            await orderDetails.map((order: any) => {
                total = total + parseInt(order.totalCost)
            });
            setTotalCost(total);
        }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handlePlaceOrder = () => {
        console.log("handlePayout");
        setPaying(true);
        console.log(orderDetails);
        console.log(shippingDetails);
        console.log(orderDetails.length);
    }

    return (
        <React.Fragment>
            <PaymentModal
                // Use a unique value for the orderId
                orderId={orderDetails.orderId}
                name={orderDetails.productId}
                amount={totalCost}
                func={paying}
                donePayment={donePayment}
                shippingDetails={shippingDetails}
                orderDetails={orderDetails}
            />
            <Typography variant="h6" gutterBottom>
                Order summary
            </Typography>
            <List disablePadding>
                {(orderDetails.length == 1 && orderDetails[0].selectedQuantity !=null)?
                    <ListItem className={classes.listItem} key={orderDetails[0].productName}>
                        <ListItemText primary={orderDetails[0].productName}
                                      secondary={orderDetails[0].productDescription}/>
                        <Typography
                            variant="body2">{"LKR "}{parseInt(orderDetails[0].productPrice) * parseInt(orderDetails[0].selectedQuantity)}</Typography>
                    </ListItem>
                    :
                    orderDetails.map((order: any) => (
                        <ListItem className={classes.listItem} key={order.productName}>
                            <ListItemText primary={order.productName} secondary={order.productDescription}/>
                            <Typography variant="body2">{"LKR "}{order.totalCost}</Typography>
                        </ListItem>
                    ))
                }
                <ListItem className={classes.listItem}>
                    <ListItemText primary="Total"/>
                    <Typography variant="subtitle1" className={classes.total}>
                        {"LKR "}{totalCost}
                    </Typography>
                </ListItem>
            </List>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                        Shipping Details
                    </Typography>
                    <Typography gutterBottom>{shippingDetails.firstName}{" "}{shippingDetails.lastName}</Typography>
                    <Typography
                        gutterBottom>{shippingDetails.addressLine1}{", "}{shippingDetails.addressLine2}</Typography>
                    <Typography
                        gutterBottom>{shippingDetails.city}{", "}{shippingDetails.province}{", "}{shippingDetails.country}</Typography>
                    <Typography gutterBottom>{shippingDetails.postalCode}</Typography>
                    <Typography gutterBottom>{shippingDetails.telephone}</Typography>

                </Grid>
            </Grid>
            <div className={classes.buttons}>
                <Button onClick={handleBack} className={classes.button}>
                    Back
                </Button>
                <Button
                    ///type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handlePlaceOrder}
                    className={classes.button}
                >
                    Place order
                </Button>
            </div>
        </React.Fragment>
    );
}