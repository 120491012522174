import { Container, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ItemCard from "../ItemCard/itemcard";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import CONSTANTS from "../../services/constants";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    cardPadding: {
      border: "2px solid #e6e6e6",
      backgroundColor: "#e6e6e6",
      borderRadius: "5px",
      marginTop: "3rem",
    },
    typeHeading: {
      textAlign: "left",
      emphasis: "medium",
    },
    categoryText: {
      marginLeft: "1.5rem",
      marginRight: "1rem",
    },
    category: {
      display: "block",
      marginLeft: "1.5rem",
      marginTop: "2rem",
      marginBottom: "1rem",
    },
  })
);

const CategoryPage = (props: any) => {
  const classes = useStyles();
  const [product, setProduct] = useState([]);

  useEffect(() => {
    // @ts-ignore
    const getProducts = async () => {
      const products = await axios.get(
        // @ts-ignore
        `${CONSTANTS.HOSTNAME}/api/products/get-products/${props.match.params.categoryName}`
      );
      setProduct(products.data.data);
    };

    getProducts();
  }, [props]);

  function FormRow() {
    return (
      <>
        {product.map((item, i) => (
          <Grid item xs={2}>
            <ItemCard key={i} product={item} />
          </Grid>
        ))}
      </>
    );
  }

  return (
    <>
      <Container maxWidth={"lg"}>
        <Grid className={classes.cardPadding} container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.category}>
              <Typography
                className={classes.categoryText}
                variant="overline"
                display="inline"
                gutterBottom
              >
                Category
              </Typography>

              <Typography
                className={classes.typeHeading}
                display="inline"
                gutterBottom
                variant="h5"
              >
                {/*// @ts-ignore*/}
                {props.match.params.categoryName}
              </Typography>
            </div>
          </Grid>
          <FormRow />
        </Grid>
      </Container>
    </>
  );
};

//@ts-ignore
export default CategoryPage;
