import { Container, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import CONSTANTS from "../../services/constants";
import ItemCard from "../ItemCard/itemcard";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import history from "../../history";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    cardPadding: {
      border: "2px solid #e6e6e6",
      backgroundColor: "#e6e6e6",
      borderRadius: "5px",
      marginTop: "3rem",
    },
    typeHeading: {
      textAlign: "left",
      // fontWeight:"light",
      emphasis: "medium",
    },
    searchText: {
      marginTop: "2rem",
      marginLeft: "1.5rem",
      marginRight: "1rem",
    },
    errorText: {
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "1rem",
    },
    category: {
      display: "block",
      marginLeft: "1.5rem",
      marginTop: "2rem",
      marginBottom: "1rem",
    },
  })
);
const SearchPage = (props: any) => {
  const classes = useStyles();
  const [state, setState] = useState({
    resultArray: [],
  });
  const searchItem = props.location.search.replace("?", "");

  useEffect(() => {
    handleSearch();
  }, [props.location.search]);

  const handleSearch = async () => {
    try {
      if (props.location.search !== undefined) {
        const searchQuery = {
          item: searchItem,
        };
        const res = await axios.post(
          CONSTANTS.HOSTNAME + "/api/common/search",
          searchQuery
        );
        // console.log(res.data.data);
        setState({
          resultArray: res.data.data,
        });
      } else {
        history.push("/");
      }
      //console.log(state.resultArray);
    } catch (err) {
      console.log(err);
    }
  };

  const FormRow = () => {
    return (
      <>
        {state.resultArray !== null ? (
          state.resultArray.map((item, i) => (
            <Grid item xs={2}>
              <ItemCard key={i} product={item} />
            </Grid>
          ))
        ) : (
          <Typography
            className={classes.errorText}
            variant="subtitle1"
            gutterBottom
          >
            No items found
          </Typography>
        )}
      </>
    );
  };

  return (
    <>
      <Container maxWidth={"lg"}>
        <Typography className={classes.searchText} variant="h5" gutterBottom>
          Search results
        </Typography>
        <Grid className={classes.cardPadding} container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.category}></div>
          </Grid>
          <FormRow />
        </Grid>
      </Container>
    </>
  );
};

export default SearchPage;
