import React, { useEffect, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Backdrop,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  Snackbar,
  TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import axios from "axios";

import CONSTANTS from "../../../services/constants";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import history from "../../../history";
// @ts-ignore
import { withSwalInstance } from "sweetalert2-react";
import Swal from "sweetalert2";
import CancelIcon from "@material-ui/icons/Cancel";
import AddCircleIcon from "@material-ui/icons/AddCircle";
// @ts-ignore
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
// @ts-ignore
import { v4 as uuid } from "uuid";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import AddProduct from "./addProduct";
import { Alert } from "@material-ui/lab";
import { Storage } from "aws-amplify";

const columns = [
  { id: "productName", label: "Product Name", minWidth: 100, align: "left" },
  {
    id: "unitPrice",
    label: "Unit Price (LKR)",
    align: "right",
    format: (value: any) => value.toFixed(2),
  },
  {
    id: "quantity",
    label: "Quantity",
    align: "left",
  },
  {
    id: "category",
    label: "Category",
    align: "left",
  },
  {
    id: "edit",
    label: "Actions",
    align: "center",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  profile: {
    padding: "2rem",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    border: "2px solid #e6e6e6",
    borderRadius: "5px",
    backgroundColor: "#e6e6e6",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    minWidth: 150,
  },
  container: {
    maxHeight: 440,
    tableLayout: "fixed",
  },
  paper: {
    marginTop: "2rem",
    width: "80%",
    marginLeft: "10%",
  },
  margin: {
    borderRadius: "50px",
    padding: "1%",
    marginRight: "11.5%",
    marginTop: "1%",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const SweetAlert = withSwalInstance(Swal);

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});


const ManageProducts = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [state, setState] = useState({
    products: [],
    categories: [],
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addingProduct, setAddingProduct] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatingInventory, setUpdatingInventory] = useState(null);

  const [inventoryError, setInventoryError] = useState("");

  useEffect(() => {
    callProduct();
  }, []);

  const callProduct = async () => {
    setLoading(true);
    try {
      const res1 = await axios.get(
        `${CONSTANTS.HOSTNAME}/api/products/get-all-products`
      );
      const products = res1.data.data;
      const res2 = await axios.get(
        `${CONSTANTS.HOSTNAME}/api/categories/get-all-categories`
      );
      const categories = res2.data.data;
      products.forEach((product: any) => {
        const category = categories.find(
          (category: any) => category.categoryId === product.categoryCategoryId
        );
        product.category = category.categoryName;
      });

      setState({
        ...state,
        products: products,
        categories: categories,
      });
      // console.log(products);
      // console.log(categories);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const handleChangePage = async (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = async (event: any, param: any, key: any) => {
    event.preventDefault();
    // console.log("You clicked edit.");
    switch (key) {
      case "edit":
        console.log(param);
        history.push({
          pathname: `/adminpanel/manage-products/edit-product`,
          state: { id: param },
        });
        break;
      case "updateInventory":
        const product = state.products.find(
          // @ts-ignore
          (product) => product.productId === param
        );
        // console.log(product);
        // @ts-ignore
        setUpdatingInventory(product);
        break;
      case "view":
        // console.log(param);
        history.push({
          pathname: `/adminpanel/manage-products/view-product`,
          state: { id: param },
        });
        break;
      case "delete":
        const res = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        });
        if (res.isConfirmed) {
          setLoading(true);
          try {
            const productToDelete = await axios.get(
              `${CONSTANTS.HOSTNAME}/api/products/get-product/${param}`
            );
            const imagesToDelete = productToDelete.data.data.productImages;
            // console.log(imagesToDelete);
            for (const item of imagesToDelete) {
              await Storage.remove(item.imageUrl);
            }
            await axios.delete(
              `${CONSTANTS.HOSTNAME}/api/products/delete-product/${param}`
            );
            setSuccess("Product deleted successfully!");
            setState({
              ...state,
              // @ts-ignore
              products: state.products.filter(
                // @ts-ignore
                (product) => product.productId !== param
              ),
            });
          } catch (e) {
            console.log(e);
          }
          setLoading(false);
        }
        break;
      default:
        break;
    }
  };

  const updateInventory = async () => {
    // @ts-ignore
    const val = updatingInventory.newQuantity;
    if (isNaN(val) || val === "" || val <= 0)
      setInventoryError("Invalid input");
    else {
      setLoading(true);
      // console.log(val);
      try {
        const res = await axios.put(
          `${CONSTANTS.HOSTNAME}/api/products/update-product-quantity`,
          // @ts-ignore
          { totalQuantity: val, productId: updatingInventory.productId }
        );
        setSuccess("Inventory updated successfully!");
        // @ts-ignore
        const indexOfToBeUpdated = state.products.findIndex(
          // @ts-ignore
          (product) => product.productId === updatingInventory.productId
        );
        const products = state.products;
        // @ts-ignore
        products[indexOfToBeUpdated].quantity = updatingInventory.newQuantity;
        setState({
          ...state,
          products: products,
        });
        // @ts-ignore
        setUpdatingInventory(null);
      } catch (e) {
        console.log(e);
        setError("Inventory update failed");
      }
      setLoading(false);
    }
  };

  const handleClose = () => {
    setInventoryError("");
    setUpdatingInventory(null);
  };

  return (
    <div className={classes.root}>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <Snackbar
        open={!!error || !!success}
        autoHideDuration={6000}
        onClose={() => {
          setError("");
          setSuccess("");
        }}
      >
        <Alert
          variant="filled"
          onClose={() => setError("")}
          severity={error !== "" ? "error" : "success"}
        >
          {error !== "" ? error : success}
        </Alert>
      </Snackbar>
      <Dialog
        onClose={handleClose}
        aria-labelledby="inventory-update-dialog"
        open={!!updatingInventory}
      >
        <DialogTitle id="inventory-update-dialog">
          Update product inventory
        </DialogTitle>
        <Divider />
        <List>
          <ListItem>
            <TextField
              label="Product Name"
              value={
                //@ts-ignore
                updatingInventory ? updatingInventory.productName : ""
              }
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              // className={classes.textField}
            />
          </ListItem>
          <ListItem>
            <TextField
              label="Current stock"
              value={
                //@ts-ignore
                updatingInventory ? updatingInventory.quantity : ""
              }
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              // className={classes.textField}
            />
          </ListItem>
          <ListItem>
            <TextField
              error={!!inventoryError}
              required
              label="New units"
              autoFocus
              onChange={(e) => {
                setInventoryError("");
                setUpdatingInventory({
                  //@ts-ignore
                  ...updatingInventory,
                  newQuantity:
                    //@ts-ignore
                    parseInt(e.target.value) + updatingInventory.quantity,
                });
              }}
              helperText={
                inventoryError || "Total units = current stock + new units"
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={updateInventory}
            >
              Update
            </Button>
          </ListItem>
        </List>
      </Dialog>
      <Grid container direction={"column"} alignItems="flex-end">
        <Button
          color={addingProduct ? "secondary" : "primary"}
          variant="contained"
          className={classes.margin}
          onClick={() => setAddingProduct(!addingProduct)}
          disabled={props.role}
        >
          {addingProduct ? (
            <CancelIcon className={classes.extendedIcon} />
          ) : (
            <AddCircleIcon className={classes.extendedIcon} />
          )}
          {addingProduct ? "Cancel" : "New Product"}
        </Button>
      </Grid>
      <Container maxWidth={"lg"}>
        <Collapse in={addingProduct}>
          <AddProduct categories={state.categories} />
        </Collapse>
        <Collapse in={!addingProduct}>
          <Paper className={classes.paper}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead style={{ backgroundColor: "black" }}>
                  <TableRow>
                    {columns.map((column) => (
                      // @ts-ignore
                      <TableCell key={column.id} align={column.align}>
                        <b>{column.label}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.products
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow>
                          {columns.map((column) => {
                            //@ts-ignore
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align="center">
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                                {column.id == "edit" && (
                                  <>
                                    <Button
                                      color="default"
                                      onClick={(e) => {
                                        handleChange(
                                          e,
                                          row["productId"],
                                          "view"
                                        );
                                      }}
                                    >
                                      VIEW
                                    </Button>
                                    <Button
                                      color="primary"
                                      disabled={props.role}
                                      onClick={(e) => {
                                        handleChange(
                                          e,
                                          row["productId"],
                                          "edit"
                                        );
                                      }}
                                    >
                                      EDIT
                                    </Button>
                                    <Button
                                      color="primary"
                                      disabled={props.role}
                                      onClick={(e) => {
                                        handleChange(
                                          e,
                                          row["productId"],
                                          "updateInventory"
                                        );
                                      }}
                                    >
                                      INVENTORY
                                    </Button>

                                    <Button
                                      style={{ color: "red" }}
                                      disabled={props.role}
                                      onClick={(e) =>
                                        handleChange(
                                          e,
                                          row["productId"],
                                          "delete"
                                        )
                                      }
                                    >
                                      DELETE
                                    </Button>
                                  </>
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={state.products.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Collapse>
      </Container>
    </div>
  );
};

export default ManageProducts;
