import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  Checkbox,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from "@material-ui/icons/Delete";
import Picture from "../../Assets/Images/DSCN0337.jpg";
import axios from "axios";
import CONSTANTS from "../../services/constants";
import CardActionArea from "@material-ui/core/CardActionArea";
import history from "../../history";
import { Storage } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1000,
    marginBottom: 20,
    marginLeft: 20,
  },
  media: {
    height: "60%",
    width: "90%", // 16:9,
    marginLeft: "10%",
    cursor: "pointer",
  },
  actionMedia: {
    marginLeft: "10%",
    marginBottom: "10%",
  },

  checkbox: {
    float: "left",
    paddingLeft: "1%",
    zIndex: 999,
  },
  title: {
    marginTop: "0.5%",
    cursor: "pointer",
  },
  description: {
    paddingBottom: "5%",
  },
  margin: {
    margin: theme.spacing(3),
  },
  buttons: {
    paddingRight: "10px",
    float: "right",
    zIndex: 999,
  },
  buttonText: {
    padding: "2px",
    float: "right",
    position: "relative",
    right: "95px",
    marginTop: "15%",
  },
}));

const CartItem = (props: any) => {
  const [checked, setChecked] = React.useState(props.checkAll);

  const [state, setState] = useState({
    itemId: props.id,
    productId: props.productId,
    productName: props.name,
    productDescription: props.description,
    unitPrice: props.unitPrice,
    totalPrice: props.total,
    selectedQuantity: props.quantity,
    productQuantity: props.productQuantity,
    checkAll: props.checkAll,
    coverImage: null,
  });
  const itemDetails = {
    quantity: state.selectedQuantity,
    totalCost: state.totalPrice,
  };

  const handleQuantity = async (event: any, key: any) => {
    switch (key) {
      case "remove":
        setState((prevState) => {
          return {
            ...prevState,
            selectedQuantity: state.selectedQuantity - 1,
            totalPrice:
              parseInt(state.unitPrice) * (state.selectedQuantity - 1),
          };
        });
        props.handleTotalCost(checked, -parseInt(state.unitPrice));
        break;
      case "add":
        setState((prevState) => {
          return {
            ...prevState,
            selectedQuantity: state.selectedQuantity + 1,
            totalPrice:
              parseInt(state.unitPrice) * (state.selectedQuantity + 1),
          };
        });
        props.handleTotalCost(checked, parseInt(state.unitPrice));
    }
  };
  useEffect(() => {
    updateItems();
  }, [handleQuantity]);

  useEffect(() => {
    const callProductImage = async () => {
      const imgURL = await Storage.get(props.coverImage);
      // @ts-ignore
      setState({
        ...state,
        // @ts-ignore
        coverImage: imgURL,
      });
      //console.log(products.data.data);
    };
    callProductImage();
  }, []);
  const updateItems = async () => {
    try {
      if (checked && state.itemId != null) {
        await axios.put(
          `${CONSTANTS.HOSTNAME}/api/cart/update-values/${state.itemId}`,
          itemDetails
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleOpenCardView = () => {
    //console.log(props.productId)
    history.push(`/item-card-view/${props.productId}`);
  };

  const handleChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setChecked(event.target.checked);
    props.handleSelect(
      state.itemId,
      event.target.checked,
      parseInt(state.totalPrice)
    );
  };

  const handleDelete = () => {
    if (state.itemId != null) {
      props.handleDelete(state.itemId, parseInt(state.totalPrice));
    }
  };

  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <CardContent>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  name="jason"
                />
              }
              label=""
            />
            <Typography
              onClick={handleOpenCardView}
              className={classes.title}
              variant="h5"
              component="h2"
            >
              {props.name}
            </Typography>
          </CardContent>
        </Grid>
        <Grid item xs={2}>
          <CardContent>
            <IconButton
              style={{ color: "#ff6666" }}
              aria-label="delete"
              onClick={handleDelete}
            >
              <DeleteIcon />
            </IconButton>
          </CardContent>
        </Grid>
        <Grid item xs={3}>
          <CardActionArea onClick={handleOpenCardView}>
            <img
              className={classes.media}
              src={state.coverImage || ""}
              alt="productImage"
            />
          </CardActionArea>
        </Grid>
        <Grid item xs={9}>
          <CardContent>
            <Typography
              className={classes.description}
              variant="body1"
              color="textSecondary"
              component="p"
            >
              {props.description}
            </Typography>
            <Typography variant="h5" color="textPrimary" component="p">
              LKR {props.unitPrice} X {state.selectedQuantity}
            </Typography>
            <Typography
              className={classes.description}
              variant="body1"
              color="textSecondary"
              component="p"
            >
              LKR {state.totalPrice}
            </Typography>
            <div className={classes.buttons}>
              {/*// @ts-ignore*/}
              <Fab
                size="small"
                color="secondary"
                disabled={state.selectedQuantity <= 1 ? true : false}
                aria-label="remove"
                className={classes.margin}
                onClick={(e) => {
                  handleQuantity(e, "remove");
                }}
              >
                <RemoveIcon />
              </Fab>
              <Typography
                className={classes.buttonText}
                variant="h6"
                color="textPrimary"
                component="p"
              >
                {state.selectedQuantity}
              </Typography>
              {/*// @ts-ignore*/}
              <Fab
                size="small"
                color="secondary"
                disabled={
                  state.selectedQuantity >= parseInt(state.productQuantity)
                    ? true
                    : false
                }
                aria-label="add"
                className={classes.margin}
                onClick={(e) => {
                  handleQuantity(e, "add");
                }}
              >
                <AddIcon />
              </Fab>
            </div>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CartItem;
