import React, { useEffect, useState } from "react";
import { Container, Badge } from "react-bootstrap";
import "./SideBar.css";
import { Link } from "react-router-dom";

const SideBar = () => {
  const [state, setState] = useState({
    complains: null,
  });

  return (
    <div className="sidebar-admin">
      <Link to="/adminpanel/">Dashboard </Link>
      <Link to="/adminpanel/manage-users">Manage Users</Link>
      <Link to="/adminpanel/manage-categories">Manage Categories</Link>
      <Link to="/adminpanel/sales">Sales</Link>
      <Link to="/adminpanel/manage-products">
        Manage Products<Badge variant="danger">{state.complains}</Badge>{" "}
      </Link>
      <Link onClick={() => (window.location.href = "/")}>Home</Link>
    </div>
  );
};

export default SideBar;
