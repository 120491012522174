import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import history from "../../history";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import CONSTANTS from "../../services/constants";
import { Storage } from "aws-amplify";
import { callback } from "chart.js/helpers";
import {Tooltip} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    maxWidth: 230,
    marginRight: "0.5rem",
    marginLeft: "0.5rem",
    marginBottom: "1rem",
  },
  media: {
    height: 140,
  },
  content:{
    minHeight: 120,
    maxHeight: 120,
  }
});

const ItemCard = (props: any) => {
  const classes = useStyles();
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    const callProductImage = async () => {
      const imgURL = await Storage.get(props.product.coverImage);
      // @ts-ignore
      setImageURL(imgURL);
      //console.log(products.data.data);
    };
    callProductImage();
  }, []);
  const handleOpenCardView = () => {
    history.push(`/item-card-view/${props.product.productId}`);
  };

  const handleBuy = () => {
    history.push(`/item-card-view/${props.product.productId}`);
  };

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={handleOpenCardView}>
        <CardMedia
          className={classes.media}
          // @ts-ignore
          image={imageURL}
          title={props.product.productName}
        />
        <CardContent className={classes.content}>
          <Tooltip title={props.product.productName} aria-label="productname">
          <Typography gutterBottom variant="subtitle1" style={{fontWeight:"bold"}}>
            {props.product.productName.length > 25 ? props.product.productName.substring(0,25)+" ...":props.product.productName}
          </Typography>
          </Tooltip>
          <Typography variant="body1" color="primary" component="p">
            {"LKR "}
            {props.product.unitPrice}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions style={{ borderTop: "1px solid rgba(0, 0, 0, 0.2)" }}>
        <Button size="small" color="primary" onClick={handleBuy}>
          BUY NOW
        </Button>
      </CardActions>
    </Card>
  );
};

export default ItemCard;
