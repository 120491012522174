import React, {useContext, useEffect, useState} from "react";
import {
    fade,
    makeStyles,
    Theme,
    createStyles,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import MoreIcon from "@material-ui/icons/MoreVert";
import history from "../../history";
import {Link} from "react-router-dom";
import {Auth} from "aws-amplify";
import axios from "axios";
import CONSTANTS from "../../services/constants";
import logo from "../../Assets/Images/Logo.png";

import {Button} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginLeft: theme.spacing(2),
            marginTop: "1%",
            fontSize: "17px",
        },
        list: {
            width: 250,
        },
        fullList: {
            width: "auto",
        },
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            display: "none",
            [theme.breakpoints.up("sm")]: {
                display: "block",
            },
        },

        search: {
            position: "relative",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            "&:hover": {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(1),
                width: "auto",
            },
        },
        searchIcon: {
            zIndex: 999,
            color: "white",
            padding: theme.spacing(0, 1),
            height: "100%",
            position: "absolute",
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
        },
        inputRoot: {
            color: "inherit",
        },
        inputInput: {
            color:"white",
            padding: theme.spacing(1, 0, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create("width"),
            width: "100%",
            [theme.breakpoints.up("md")]: {
                width: "20ch",
            },
        },
        sectionDesktop: {
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "flex",
            },
        },
        sectionMobile: {
            display: "flex",
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
        logo: {
            maxWidth: 130,
            margin: "5px 0 5px 0",
        },
        pageLinks: {
            display: "flex",
            marginRight: "30px",
        },
        links: {
            color:"white",
            marginRight: "30px",
            "&:hover": {
                color: "#b6ff6f",
            },
        },
        dropdown: {
            addingLeft: "10px",
            paddingTop: "10px",
            paddingBottom: "10px",
            color: "white",
            backgroundColor: "#425c29",
            borderColor: "#425c29",
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px",
            fontFamily: "roboto",
        },
        iconBut: {
            color: "white",
        }
    })
);

export const MainMenu = (props: any) => {
    const classes = useStyles();
    const [isAuthenticated, setAuthenticated] = React.useState(
        props.isAuthenticated
    );
    const [state, setState] = useState({
        cartItems: 0,
        userID: localStorage.getItem("userID"),
        ROLE: localStorage.getItem("ROLE"),
    });
    const [search, setSearch] = useState("");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    useEffect(() => {
        setAuthenticated(props.isAuthenticated);
        if (props.isAuthenticated) callCartItems();
    }, [props.isAuthenticated]);

    const callCartItems = async () => {
        try {
            const res = await axios.get(
                `${CONSTANTS.HOSTNAME}/api/cart/get-item-count/${state.userID}`
            );
            //console.log(res.data.data[0].items);
            setState({
                ...state,
                cartItems: parseInt(res.data.data[0].items),
            });
        } catch (e) {
        }
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = async () => {
        try {
            await Auth.signOut();
            setAuthenticated(false);
            localStorage.removeItem("userID");
            localStorage.removeItem("ROLE");
            handleMenuClose();
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    const handleOpenCartPage = () => {
        history.push({
            pathname: "/user-cart",
            state: {id: state.userID, cartItems: state.cartItems},
        });
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        // history.push("/");
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const guestLinks = (
        <>
            <Typography className={classes.root}>
                <Link to="/login" style={{textDecoration: "none", color: "inherit"}}>
                    Login
                </Link>
            </Typography>
            <Typography className={classes.root}>|</Typography>
            <Typography className={classes.root}>
                <Link
                    to="/signup"
                    color="inherit"
                    style={{textDecoration: "none", color: "inherit"}}
                >
                    Signup
                </Link>
            </Typography>
        </>
    );

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: "top", horizontal: "right"}}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => history.push("/account")}>My account</MenuItem>
            {state.ROLE != "USER" && (
                <MenuItem onClick={() => history.push("/adminpanel")}>
                    Admin Panel
                </MenuItem>
            )}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: "top", horizontal: "right"}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem  className={classes.iconBut}>
                <IconButton
                    className={classes.iconBut}
                    onClick={handleOpenCartPage}
                    aria-label="show 2 new cart items"
                    color="inherit"
                >
                    <Badge badgeContent={2} color="secondary">
                        <ShoppingCartIcon/>
                    </Badge>
                </IconButton>
                <p>Cart items</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    className={classes.iconBut}
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle className={classes.iconBut} color="inherit"/>
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );
    const handleSearch = async (event: any) => {
        event.preventDefault();
        console.log(search);
        if (search !== "")
            history.push({
                pathname: "/search",
                search: search,
            });
    };
    return (
        <div className={classes.grow}>
            <AppBar position="static" style={{background: "#214003"}}>
                <Toolbar>
                    <Link to="/">
                        <img src={logo} alt="logo" className={classes.logo}/>
                    </Link>
                    <div className={classes.grow}/>
                    <div className={classes.pageLinks}>
                        <Typography className={classes.links} variant="overline" noWrap>
                            <Link to="/" style={{textDecoration: "none", color: "inherit"}}>
                                Home
                            </Link>
                        </Typography>
                        <Typography className={classes.links} variant="overline" noWrap>
                            <Link
                                to="/about"
                                style={{textDecoration: "none", color: "inherit"}}
                            >
                                About Us
                            </Link>
                        </Typography>
                        <Typography className={classes.links} variant="overline" noWrap>
                            <Link
                                to="/contact"
                                style={{textDecoration: "none", color: "inherit"}}
                            >
                                Contact Us
                            </Link>
                        </Typography>
                        <Typography className={classes.links} variant="overline" noWrap>
                            <Link
                                to="/policies"
                                style={{textDecoration: "none", color: "inherit"}}
                            >
                                Policies
                            </Link>
                        </Typography>
                    </div>

                    <form onSubmit={handleSearch}>
                        <div className={classes.search}>
                            <IconButton
                                className={classes.searchIcon}
                                type="submit"
                                color="inherit"
                            >
                                <SearchIcon/>
                            </IconButton>
                            <InputBase
                                placeholder="Search…"
                                // @ts-ignore
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </form>

                    <div className={classes.sectionDesktop}>
                        {isAuthenticated ? (
                            <>
                                <IconButton
                                    className={classes.iconBut}
                                    onClick={handleOpenCartPage}
                                    aria-label="show 2 new cart items"
                                    color="inherit"
                                >
                                    {state.cartItems != 0 ? (
                                        <Badge badgeContent={state.cartItems} color="secondary">
                                            <ShoppingCartIcon/>
                                        </Badge>
                                    ) : (
                                        <Badge badgeContent={null} color="secondary">
                                            <ShoppingCartIcon/>
                                        </Badge>
                                    )}
                                </IconButton>
                                <IconButton
                                    className={classes.iconBut}
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <AccountCircle/>
                                </IconButton>
                            </>
                        ) : (
                            guestLinks
                        )}
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            className={classes.iconBut}
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon/>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
};

export default MainMenu;
