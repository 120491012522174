import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Backdrop,
  TextField,
  Box,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import history from "../../../../history";
import axios from "axios";
import CONSTANTS from "../../../../services/constants";
import { Storage } from "aws-amplify";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ListIcon from "@material-ui/icons/List";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  profile: {
    padding: "2rem",
    marginLeft: "auto",
    marginRight: "auto",
    width: "40%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    border: "2px solid #e6e6e6",
    borderRadius: "5px",
    backgroundColor: "#e6e6e6",
  },
  heading: {
    marginBottom: "2rem",
  },
  textField: {
    marginBottom: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  navButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2rem",
  },
  delButton: {
    backgroundColor: "#c62828",
    color: "white",
    "&:hover": {
      backgroundColor: "#921d1d",
    },
  },
  editButton: {
    backgroundColor: "#1a4fd6",
    color: "white",
    "&:hover": {
      backgroundColor: "#103288",
    },
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  title: {
    color: "white",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

const ViewProductPage = (props: any) => {
  const classes = useStyles();
  const [product, setProduct] = useState({
    productId: "",
    productName: "",
    categoryName: "",
    productDescription: "",
    availableQuantity: 0,
    unitPrice: 0,
  });
  const [productImages, setProductImages] = useState([]);
  const [imageKeys, setImageKeys] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getProductDetails().then((productImages) => {
      setImageKeys(productImages.map((img: any) => img.imageUrl));
      fetchImageURLs(productImages).then(() => setLoading(false));
    });
  }, []);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const getProductDetails = async () => {
    try {
      const product = await axios.get(
        `${CONSTANTS.HOSTNAME}/api/products/get-product/${props.location.state.id}`
      );
      const category = await axios.get(
        `${CONSTANTS.HOSTNAME}/api/categories/get-category/${product.data.data.categoryCategoryId}`
      );
      setProduct({
        productId: product.data.data.productId,
        productName: product.data.data.productName,
        categoryName: category.data.data.categoryName,
        productDescription: product.data.data.productDescription,
        availableQuantity: product.data.data.quantity,
        unitPrice: product.data.data.unitPrice,
      });
      return product.data.data.productImages;
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const fetchImageURLs = async (images: any) => {
    try {
      const tempImageURLs = [];
      for (const image of images) {
        const imageURL = await Storage.get(image.imageUrl);
        tempImageURLs.push(imageURL);
      }
      // @ts-ignore
      setProductImages(tempImageURLs);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleDelete = async () => {
    const res = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (res.isConfirmed) {
      setLoading(true);
      try {
        for (const key of imageKeys) {
          await Storage.remove(key);
        }
        await axios.delete(
          `${CONSTANTS.HOSTNAME}/api/products/delete-product/${product.productId}`
        );
        Toast.fire({
          icon: "success",
          title: "Product Deleted Successfully",
        });
        history.push("/adminpanel/manage-products");
      } catch (err) {
        setLoading(false);
        console.log(err);
        Swal.fire(
          "Cannot Delete Product.",
          `${err.response.data.error}`,
          "info"
        );
      }
    }
  };

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <form className={classes.profile} noValidate>
        <Typography variant="h4" gutterBottom className={classes.heading}>
          Product details
        </Typography>

        <TextField
          fullWidth
          label="Product Name"
          value={product.productName}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
        />
        <TextField
          fullWidth
          label="Product Description"
          multiline
          value={product.productDescription}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
        />
        <TextField
          fullWidth
          label="Product Price"
          value={product.unitPrice}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
        />
        <TextField
          fullWidth
          label="Product Quantity"
          value={product.availableQuantity}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
        />
        <TextField
          fullWidth
          label="Category Name"
          value={product.categoryName}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
        />
        <Typography variant="h6" gutterBottom className={classes.heading}>
          Product images
        </Typography>
        <Box mt={2}>
          <GridList className={classes.gridList} cols={2.5}>
            {productImages.map((image, index) => {
              return (
                <GridListTile key={index}>
                  <img src={image} alt={`Image ${index + 1}`} />
                  <GridListTileBar
                    title={`Image ${index + 1}`}
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                    }}
                  />
                </GridListTile>
              );
            })}
          </GridList>
        </Box>
        <div>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={5} className={classes.navButtons}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/adminpanel/manage-products")}
              >
                <ListIcon />
                &nbsp;View products list
              </Button>
            </Grid>
            <Grid item xs={3} className={classes.navButtons}>
              <Button
                variant="contained"
                disabled={props.role}
                className={classes.delButton}
                onClick={handleDelete}
              >
                <DeleteForeverIcon />
                &nbsp;delete
              </Button>
            </Grid>
            <Grid item xs={4} className={classes.navButtons}>
              <Button
                variant="contained"
                disabled={props.role}
                className={classes.editButton}
                onClick={() =>
                  history.push({
                    pathname: `/adminpanel/manage-products/edit-product`,
                    state: { id: product.productId },
                  })
                }
              >
                edit product&nbsp;
                <ArrowForwardIcon />
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
};

export default ViewProductPage;
