import React, { useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Collapse,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
//@ts-ignore
import { v4 as uuid } from "uuid";
import Button from "@material-ui/core/Button";
import axios from "axios";
import CONSTANTS from "../../../../services/constants";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import Swal from "sweetalert2";
import { Storage } from "aws-amplify";
import history from "../../../../history";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
//@ts-ignore
import ReactCrop from "react-image-crop";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ImageIcon from "@material-ui/icons/Image";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  heading: {
    marginBottom: "2rem",
  },
  profile: {
    padding: "2rem",
    marginLeft: "auto",
    marginRight: "auto",
    width: "40%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    border: "2px solid #e6e6e6",
    borderRadius: "5px",
    backgroundColor: "#e6e6e6",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  navButtons: {
    marginTop: "2rem",
  },
  button: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  textField: {
    marginBottom: "15px",
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: "white",
  },
  deleteButton: {
    color: "#c62828",
  },
  cancelButton: {
    marginTop: "1rem",
  },
  deleteProductButton: {
    backgroundColor: "#c62828",
    color: "white",
    marginRight: "1rem",
    marginTop: "1rem",
    "&:hover": {
      backgroundColor: "#921d1d",
    },
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast: any) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const EditproductPage = (props: any) => {
  const classes = useStyles();
  const productNameRef = useRef<HTMLInputElement>();
  const productDescriptionRef = useRef<HTMLInputElement>();
  const productPriceRef = useRef<HTMLInputElement>();
  const productQuantityRef = useRef<HTMLInputElement>();
  const productCategoryRef = useRef<HTMLInputElement>();

  const [product, setProduct] = useState({
    productId: "",
    productName: "",
    categoryId: "",
    productDescription: "",
    availableQuantity: 0,
    unitPrice: 0,
  });
  const [productImages, setProductImages] = useState([]);
  const [imageKeys, setImageKeys] = useState([]);
  const [categories, setCategories] = useState([]);
  const [imageKeysToDelete, setImageKeysToDelete] = useState([]);

  const [productImage, setProductImage] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1 });
  const [results, setResults] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [cropping, setCropping] = useState(false);
  const [success, setSuccess] = useState("");

  const [loading, setLoading] = useState(false);
  const [productNameError, setProductNameError] = useState("");
  const [productDescriptionError, setProductDescriptionError] = useState("");
  const [productPriceError, setProductPriceError] = useState("");
  const [productQuantityError, setProductQuantityError] = useState("");
  const [productCategoryError, setProductCategoryError] = useState("");

  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    getProductDetails().then((productImages) => {
      setImageKeys(productImages.map((img: any) => img.imageUrl));
      fetchImageURLs(productImages).then(() => setLoading(false));
    });
  }, []);

  const getProductDetails = async () => {
    try {
      const productRes = await axios.get(
        `${CONSTANTS.HOSTNAME}/api/products/get-product/${props.location.state.id}`
      );
      const categoriesRes = await axios.get(
        `${CONSTANTS.HOSTNAME}/api/categories/get-all-categories`
      );
      setProduct({
        productId: productRes.data.data.productId,
        productName: productRes.data.data.productName,
        categoryId: productRes.data.data.categoryCategoryId,
        productDescription: productRes.data.data.productDescription,
        availableQuantity: productRes.data.data.quantity,
        unitPrice: productRes.data.data.unitPrice,
      });
      setCategories(categoriesRes.data.data);
      // @ts-ignore
      productNameRef.current.value = productRes.data.data.productName;
      // @ts-ignore
      productDescriptionRef.current.value =
        productRes.data.data.productDescription;
      // @ts-ignore
      productPriceRef.current.value = productRes.data.data.unitPrice;
      // @ts-ignore
      productQuantityRef.current.value = productRes.data.data.quantity;
      return productRes.data.data.productImages;
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const fetchImageURLs = async (images: any) => {
    try {
      const tempImageURLs = [];
      for (const image of images) {
        const imageURL = await Storage.get(image.imageUrl);
        tempImageURLs.push({
          type: "S3",
          src: imageURL,
          key: image.imageUrl,
        });
      }
      // console.log(tempImageURLs);
      // @ts-ignore
      setProductImages(tempImageURLs);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleProductImage = async (event: any) => {
    if (event.target.files.length != 0) {
      // @ts-ignore
      setProductImage(URL.createObjectURL(event.target.files[0]));
      setCropping(true);
    }
  };

  const validateFields = (detailsToValidate: any) => {
    const { productName, productDescription, unitPrice, quantity, categoryId } =
      detailsToValidate;
    if (productName === "") setProductNameError("Cannot be empty");
    else if (productDescription === "")
      setProductDescriptionError("Cannot be empty");
    else if (unitPrice === "" || isNaN(parseInt(unitPrice)))
      setProductPriceError("Invalid input");
    else if (parseInt(unitPrice) < 50)
      setProductPriceError("Price should be grater than LKR 50");
    else if (quantity === "" || isNaN(parseInt(quantity)))
      setProductQuantityError("Invalid input");
    else if (categoryId === "")
      setProductCategoryError("Select product category");
    else if (productImages.length === 0) {
      setError("Add at least one product image!");
    } else return true;
  };

  const updateProduct = async (event: any) => {
    event.preventDefault();
    //@ts-ignore

    const updatedProduct = {
      productId: product.productId,
      productName: "",
      productDescription: "",
      unitPrice: "",
      quantity: "",
      categoryId: "",
      productImages: [],
    };

    if (productNameRef.current)
      updatedProduct.productName = productNameRef.current.value;
    if (productDescriptionRef.current)
      updatedProduct.productDescription = productDescriptionRef.current.value;
    if (productPriceRef.current)
      updatedProduct.unitPrice = productPriceRef.current.value;
    if (productQuantityRef.current)
      updatedProduct.quantity = productQuantityRef.current.value;
    if (productCategoryRef.current)
      updatedProduct.categoryId = productCategoryRef.current.value;
    if (validateFields(updatedProduct)) {
      setLoading(true);
      //@ts-ignore
      const imagesToUpdate = [];
      for (const file of results) {
        //@ts-ignore
        const imageKey = file.image.name;
        //@ts-ignore
        await Storage.put(imageKey, file.image);
        imagesToUpdate.push({ imageKey, action: "add" });
      }

      for (const imageKey of imagesToDelete) {
        await Storage.remove(imageKey);
        imagesToUpdate.push({ imageKey, action: "remove" });
      }

      //@ts-ignore
      updatedProduct.productImages = imagesToUpdate;
    }

    try {
      await axios.put(
        `${CONSTANTS.HOSTNAME}/api/products/update-product`,
        updatedProduct
      );
      setSuccess("Product details updated!");
      history.push({
        pathname: "/adminpanel/manage-products/view-product",
        state: {
          id: product.productId,
        },
      });
    } catch (e) {
      setError(e.response.data);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    const res = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (res.isConfirmed) {
      setLoading(true);
      try {
        for (const key of imageKeys) {
          await Storage.remove(key);
        }
        await axios.delete(
          `${CONSTANTS.HOSTNAME}/api/products/delete-product/${product.productId}`
        );
        setSuccess("Product deleted successfully!");
        history.push("/adminpanel/manage-products");
      } catch (err) {
        setLoading(false);
        console.log(err);
        setError("Product deleting failed");
      }
    }
  };

  const getCroppedImg = () => {
    const canvas = document.createElement("canvas");
    // @ts-ignore
    const scaleX = image.naturalWidth / image.width;
    // @ts-ignore
    const scaleY = image.naturalHeight / image.height;
    // @ts-ignore
    canvas.width = crop.width;
    // @ts-ignore
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    // @ts-ignore
    ctx.drawImage(
      // @ts-ignore
      image,
      // @ts-ignore
      crop.x * scaleX,
      // @ts-ignore
      crop.y * scaleY,
      // @ts-ignore
      crop.width * scaleX,
      // @ts-ignore
      crop.height * scaleY,
      0,
      0,
      // @ts-ignore
      crop.width,
      // @ts-ignore
      crop.height
    );
    try {
      const croppedImageDataURL = canvas.toDataURL("image/*");
      const urlDetails = croppedImageDataURL.split(",");
      // @ts-ignore
      const mime = urlDetails[0].match(/:(.*?);/)[1];
      const bstr = atob(urlDetails[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const key = product.productName + uuid();
      // @ts-ignore
      setResults([
        // @ts-ignore
        ...results,
        // @ts-ignore
        { image: new File([u8arr], key, { type: mime }), key: key },
      ]);
      // @ts-ignore
      setProductImages([
        // @ts-ignore
        ...productImages,
        {
          // @ts-ignore
          type: "NEW",
          // @ts-ignore
          src: croppedImageDataURL,
          // @ts-ignore
          key: key,
        },
      ]);
      setProductImage(null);
      setCropping(false);
      setEmpty(false);
    } catch (e) {
      setError("You need to crop image");
    }
  };

  const deleteImage = (imageDetails: any) => {
    if (productImages.length === 1) setEmpty(true);
    if (imageDetails.type === "S3") {
      // @ts-ignore
      setImagesToDelete([...imagesToDelete, imageDetails.key]);
    } else {
      // @ts-ignore
      setResults(
        // @ts-ignore
        results.filter((file) => file.image.name !== imageDetails.key)
      );
    }
    // @ts-ignore
    setProductImages(
      // @ts-ignore
      productImages.filter((img) => img.key !== imageDetails.key)
    );
  };

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <Snackbar
        open={!!error || !!success}
        autoHideDuration={6000}
        onClose={() => {
          setError("");
          setSuccess("");
        }}
      >
        <Alert
          variant="filled"
          onClose={() => setError("")}
          severity={error !== "" ? "error" : "success"}
        >
          {error !== "" ? error : success}
        </Alert>
      </Snackbar>
      <form className={classes.profile} onSubmit={updateProduct} noValidate>
        <Typography variant="h4" gutterBottom className={classes.heading}>
          Edit product details
        </Typography>
        <TextField
          error={!!productNameError}
          required
          fullWidth
          label="Product Name"
          inputRef={productNameRef}
          onChange={() => {
            if (productNameError !== "") setProductNameError("");
            if (error !== "") setError("");
          }}
          helperText={productNameError}
          className={classes.textField}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          error={!!productDescriptionError}
          required
          fullWidth
          label="Product Description"
          multiline
          inputRef={productDescriptionRef}
          onChange={() => {
            if (productDescriptionError !== "") setProductDescriptionError("");
            if (error !== "") setError("");
          }}
          className={classes.textField}
          helperText={productDescriptionError}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          error={!!productPriceError}
          required
          fullWidth
          label="Product Price"
          inputRef={productPriceRef}
          onChange={() => {
            if (productPriceError !== "") setProductPriceError("");
            if (error !== "") setError("");
          }}
          className={classes.textField}
          helperText={productPriceError}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          error={!!productQuantityError}
          required
          fullWidth
          label="Product Quantity"
          inputRef={productQuantityRef}
          onChange={() => {
            if (productQuantityError !== "") setProductQuantityError("");
            if (error !== "") setError("");
          }}
          className={classes.textField}
          helperText={productQuantityError}
          InputLabelProps={{ shrink: true }}
        />
        <FormControl fullWidth>
          <InputLabel
            id="product-category-select-label"
            error={!!productCategoryError}
          >
            Product category
          </InputLabel>
          <Select
            labelId="product-category-select-label"
            id="demo-simple-select"
            className={classes.textField}
            inputRef={productCategoryRef}
            value={product.categoryId}
            onChange={(e) => {
              // @ts-ignore
              setProduct({ ...product, categoryId: e.target.value });
              if (productCategoryError !== "") setProductCategoryError("");
              if (error !== "") setError("");
            }}
          >
            {categories.map((category: any) => (
              <MenuItem
                key={category["categoryName"]}
                value={category["categoryId"]}
              >
                {category["categoryName"]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Container maxWidth={"sm"}>
          <div>
            <Collapse in={!cropping && productImages.length < 5}>
              <Box mt={2} ml={-5} pb={2}>
                <Button
                  variant="contained"
                  component="label"
                  color={"secondary"}
                >
                  <ImageIcon />
                  &nbsp;
                  {`Select product images ${productImages.length}/5`}
                  <input type="file" hidden onChange={handleProductImage} />
                </Button>
              </Box>
            </Collapse>
            <div>
              <Collapse in={!!productImage}>
                <Box mt={2}>
                  <ReactCrop
                    style={{ maxWidth: "100%" }}
                    src={productImage}
                    onImageLoaded={setImage}
                    crop={crop}
                    onChange={setCrop}
                  />
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={getCroppedImg}
                  >
                    crop
                  </Button>
                </Box>
              </Collapse>
            </div>
          </div>
        </Container>
        <Collapse in={!empty}>
          <Box mt={2}>
            <GridList className={classes.gridList} cols={2.5}>
              {productImages.map((image, index) => (
                <GridListTile key={index}>
                  {/*@ts-ignore*/}
                  <img src={image.src} alt={`Image ${index + 1}`} />
                  <GridListTileBar
                    title={`Image ${index + 1}`}
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                    }}
                    actionIcon={
                      <IconButton
                        aria-label={`star ${index}`}
                        onClick={() => deleteImage(image)}
                      >
                        <DeleteIcon className={classes.deleteButton} />
                      </IconButton>
                    }
                  />
                </GridListTile>
              ))}
            </GridList>
          </Box>
        </Collapse>
        <Grid container spacing={0} className={classes.navButtons}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className={classes.button}
            >
              <SaveIcon />
              &nbsp;Save changes
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={2} />
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  className={classes.deleteProductButton}
                  onClick={handleDelete}
                >
                  <DeleteForeverIcon />
                  &nbsp;delete
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  className={classes.cancelButton}
                  onClick={() =>
                    history.push({
                      pathname: `/adminpanel/manage-products`,
                      state: { id: product.productId },
                    })
                  }
                >
                  <CloseIcon />
                  &nbsp;Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default EditproductPage;
