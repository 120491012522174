import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Carousel from "react-multi-carousel";
import About1 from "../../Assets/Images/About/1N.jpg";
import About2 from "../../Assets/Images/About/2N.jpg";
import About3 from "../../Assets/Images/About/3N.jpg";
import About4 from "../../Assets/Images/About/4N.jpg";
import About5 from "../../Assets/Images/About/5N.jpg";
import About6 from "../../Assets/Images/About/6N.jpg";
import About7 from "../../Assets/Images/About/7N.jpg";
import About8 from "../../Assets/Images/About/8N.jpg";
import About9 from "../../Assets/Images/About/9N.jpg";
import About10 from "../../Assets/Images/About/10N.jpg";
import Logo from "../../Assets/Images/Logo-only.png";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    cardPadding: {
      paddingTop: "40px",
    },
    media: {
      height: "550px",
      width: "95%",
      borderRadius: "40px",
      cursor: "pointer",
      filter: "brightness(55%)",
      border: "none",
      paddingRight: "10px",
      paddingLeft: "10px",
    },
    carouselSetStyle: {
      marginTop: "5%",
      marginLeft: "5%",
    },
    mediaText: {
      position: "absolute",
      color: "white",
      top: "50%",
      left: "50%",
      fontSize: "200%",
      fontWeight: "lighter",
      transform: "translate(-50%, -50%)",
      //fontFamily: "Marker Felt, fantasy",
      textShadow: "1px 1px 2px #000000",
      textAlign: "center",
      cursor: "pointer",
    },
    gridList: {
      width: "100%",
      height: 820,
    },
  })
);

const tileData = [
  {
    img: About4,
    title: "Image",
    author: "author",
    cols: 1,
  },
  {
    img: About10,
    title: "Image",
    author: "author",
    cols: 1,
  },
  {
    img: About5,
    title: "Image",
    author: "author",
    cols: 1,
  },
  {
    img: About3,
    title: "Image",
    author: "author",
    cols: 2,
  },
  {
    img: About7,
    title: "Image",
    author: "author",
    cols: 1,
  },
  {
    img: About6,
    title: "Image",
    author: "author",
    cols: 1,
  },
  {
    img: About8,
    title: "Image",
    author: "author",
    cols: 1,
  },
  {
    img: About1,
    title: "Image",
    author: "author",
    cols: 1,
  },
  {
    img: About9,
    title: "Image",
    author: "author",
    cols: 1,
  },
  {
    img: About2,
    title: "Image",
    author: "author",
    cols: 2,
  },
];

const items = [
  {
    Image: About1,
    Text:
      "Black tea is known to be outstanding protection against cancer, the theaflavins and the thearubigins present in black tea " +
      "are well-known anti-oxidants that help to combat free radicals that can cause harm to DNA which causes cancer in your body. ",
  },
  {
    Image: About2,
    Text:
      "Consuming black tea consistently can reduce the chances of heart diseases. The antioxidants found in black tea can assist " +
      "in lowering cholesterol levels inside blood vessels, thus averting high blood pressure and strokes.",
  },
  {
    Image: About3,
    Text:
      "These anti-oxidants can also aid in reducing the size of tumors. Similar to green tea, Ceylon Black tea boosts mental " +
      "alertness and acuity by resulting increase in alpha-wave activity in the brain. Ceylon black tea is also a well-known remedy to reduce stress in your system.",
  },
  {
    Image: About4,
    Text:
      "The regular consumption of black tea is useful in keeping oneself safe from viruses and bacteria and it also decreases " +
      "the possibility of oneself catching influenza and enhances the immune system to prevent oneself from catching the majority of any disease.",
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const Item = (props: any) => {
  return (
    <div>
      <img
        className={props.class.media}
        src={props.item.Image}
        alt="Image couldn't load"
      />
      <div className={props.class.mediaText}>{props.item.Text}</div>
    </div>
  );
};

const Aboutpage = () => {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth={"lg"}>
        <Grid item xs={12}>
          <div className={classes.carouselSetStyle}>
            <Carousel
              swipeable={true}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={4000}
              keyBoardControl={true}
              customTransition="transform 1000ms ease-in-out"
              containerClass="carousel-container"
              removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
              dotListClass="react-multi-carousel-dot-list"
              itemClass="carousel-item-padding-40-px"
              centerMode={false}
            >
              {items.map((item, i) => (
                <Item key={i} item={item} class={classes} />
              ))}
            </Carousel>
          </div>
        </Grid>
        <Grid>
          <div style={{ display: "flex", marginTop: "20px", justifyContent: "center"}}>
            <img src={Logo} height={"55px"} />
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              &nbsp;Kudamalana Tea Factory
            </Typography>
          </div>
          <hr />
          <Typography
            variant="h6"
            style={{
              marginTop: "40px",
              textAlign: "center",
              fontFamily: '"Times New Roman", Times, serif',
              fontWeight: "bold",
            }}
          >
            Kudamalana Tea Factory (MF 259)
            </Typography>
          <Typography
            variant="h6"
            style={{
              marginTop: "40px",
              textAlign: "justify",
              fontFamily: '"Times New Roman", Times, serif',
            }}>
              Kudamalana Tea Factory is located in Kudamalana Organic Estate in the southern part of Sri Lanka with coordinates of 
              6.266573965398168, 80.28386945537397. It was started in 1919 by Mr.Erskin a migrant planter from Scotland and gifted 
              to Mr. Charles who was his Tea Maker in the factory. After Late Mr. Charles, his elder son Eddie Premasiri took over 
              the management. Presently the Estate and the Tea Factory Own by Kudamanana (Pvt) Ltd, since 1993, the Company been 
              managing the second generation of Mr. Charles.
            </Typography>
            <Typography
            variant="h6"
            style={{
              marginTop: "40px",
              textAlign: "justify",
              fontFamily: '"Times New Roman", Times, serif',
            }}>
              Low-grown teas are teas grown on an elevation between sea level to 300m- 600m. Low-grown teas, which are subjected to 
              long periods of sunshine, dry and somewhat warm and moist conditions, exhibit a burgundy-brown liquor and a malt, 
              heavy note with black leaf appearance. The soil, combined with the low elevation of the estates, causes the tea-bush 
              to grow rapidly, producing a long, beautiful leaf. Full-flavored black tea is a distinctively unique 'Ruhuna' 
              specialty. 'Ruhuna' sub-sector of Low- Grown tea in Sri Lanka, which geographically located outskirts of the south, 
              and southwest of 'Sinharaja Natural Forest'. Kudamala also represents 'sub-cult' 'Ruhuna' in Sri Lanka's Low Grown 
              sector. 'Ruhuna' factories produce a wide variety of leaf styles and sizes, including high prized 'tips'.
            </Typography>
            <Typography
            variant="h6"
            style={{
              marginTop: "40px",
              textAlign: "justify",
              fontFamily: '"Times New Roman", Times, serif',
            }}>
              Apart from its main business of Tea, it also carries out value-added products in spice and other agricultural products 
              of the area on small scale as social inclusion project. Total Kudamalana Estate is Organic Form, No Chemical fertilizer 
              or Chemical Pesticides used during last six years.
            </Typography>
        </Grid>

        <Grid style={{ marginTop: "40px" }}>
          <GridList cellHeight={200} className={classes.gridList} cols={3}>
            {tileData.map((tile) => (
              <GridListTile key={tile.img} cols={tile.cols || 1}>
                <img src={tile.img} alt={"Couldn't load images"} />
              </GridListTile>
            ))}
          </GridList>
        </Grid>
      </Container>
    </>
  );
};

//@ts-ignore
export default Aboutpage;
